<template>
    <activix-modal
        size="sm"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.boxes.general.contactPreference') }}
            </h4>
        </template>

        <template slot="body">
            <div class="space-y-6">
                <div class="flex justify-between items-center">
                    <div class="flex justify-center items-center">
                        <icon class="mr-2 text-xl" name="regular/remove-circle" />
                        <span>
                            {{ $t('clientCard.boxes.general.noPreferences') }}
                        </span>
                    </div>

                    <div class="flex items-center space-x-3">
                        <activix-radio
                            name="communicationPreference"
                            value="none"
                            :model-value="currentPreferredMethod"
                            @input="preferredMethodSelected = $event"
                        />
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div class="flex justify-center items-center">
                        <icon class="mr-2 text-xl" :name="$icons.phone" />
                        <span>
                            {{ $t('clientCard.call') }}
                        </span>
                    </div>

                    <activix-tooltip :content="phoneTooltip">
                        <div class="flex items-center space-x-3">
                            <activix-radio
                                name="communicationPreference"
                                value="phone"
                                :model-value="currentPreferredMethod"
                                @input="preferredMethodSelected = $event"
                            /></div>
                    </activix-tooltip>
                </div>

                <div class="flex justify-between items-center">
                    <div class="flex justify-center items-center">
                        <icon class="mr-2 text-xl" :name="$icons.email" />
                        <span>
                            {{ $t('clientCard.email') }}
                        </span>
                    </div>

                    <activix-tooltip :content="emailTooltip">
                        <div class="flex items-center space-x-3">
                            <activix-radio
                                name="communicationPreference"
                                value="email"
                                :model-value="currentPreferredMethod"
                                @input="preferredMethodSelected = $event"
                            />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="flex justify-between items-center" :class="{ 'text-gray-500': !hasNiotext }">
                    <div class="flex justify-center items-center">
                        <icon class="mr-2 text-xl" :name="$icons.sms" />
                        <span>
                            {{ $t('clientCard.smsTheClient') }}
                        </span>
                    </div>

                    <activix-tooltip :content="smsTooltip">
                        <div class="flex items-center space-x-3">
                            <activix-radio
                                name="communicationPreference"
                                value="sms"
                                :disabled="!hasNiotext"
                                :model-value="currentPreferredMethod"
                                @input="preferredMethodSelected = $event"
                            />
                        </div>
                    </activix-tooltip>
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>

            <activix-button type="primary" @click="save">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            communicationPreference: {
                type: String,
                default: null,
            },
            hasNiotext: {
                type: Boolean,
                required: true,
            },
            unsubscribedToAllDate: {
                type: String,
                default: null,
            },
            unsubscribedToEmailDate: {
                type: String,
                default: null,
            },
            unsubscribedToPhoneDate: {
                type: String,
                default: null,
            },
            unsubscribedToSmsDate: {
                type: String,
                default: null,
            },
        },

        data: () => ({
            preferredMethodSelected: null,
        }),

        computed: {
            currentPreferredMethod() {
                return this.preferredMethodSelected || this.communicationPreference || 'none';
            },

            emailTooltip() {
                if (!this.isUnsubscribedToEmail) {
                    return '';
                }

                if (this.isUnsubscribedToAll) {
                    return this.unsubscribedToAllTooltip;
                }

                return this.$t('clientCard.unsubscribedSince', [new ActivixDate(this.unsubscribedToEmailDate).toHumanShort(false)]);
            },

            isUnsubscribedToAll() {
                return !!this.unsubscribedToAllDate;
            },

            isUnsubscribedToEmail() {
                return !!this.unsubscribedToEmailDate;
            },

            isUnsubscribedToPhone() {
                return !!this.unsubscribedToPhoneDate;
            },

            isUnsubscribedToSms() {
                return !!this.unsubscribedToSmsDate;
            },

            phoneTooltip() {
                if (!this.isUnsubscribedToPhone) {
                    return '';
                }

                if (this.isUnsubscribedToAll) {
                    return this.unsubscribedToAllTooltip;
                }

                return this.$t('clientCard.unsubscribedSince', [new ActivixDate(this.unsubscribedToPhoneDate).toHumanShort(false)]);
            },

            smsTooltip() {
                if (!this.hasNiotext) {
                    return this.$t('modal.niotextNeeded');
                }

                if (!this.isUnsubscribedToSms) {
                    return '';
                }

                if (this.isUnsubscribedToAll) {
                    return this.unsubscribedToAllTooltip;
                }

                return this.$t('clientCard.unsubscribedSince', [new ActivixDate(this.unsubscribedToSmsDate).toHumanShort(false)]);
            },

            unsubscribedToAllTooltip() {
                if (this.isUnsubscribedToAll) {
                    const unsubscribedToAllDate = new ActivixDate(this.unsubscribedToAllDate);

                    return unsubscribedToAllDate.year === 2222
                        ? this.$t('clientCard.clientIsAllUnsubscribedForever')
                        : this.$t('clientCard.clientIsAllUnsubscribed', [unsubscribedToAllDate.toHumanShort(false)]);
                }

                return '';
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },

            onClosed() {
                this.preferredMethodSelected = null;
            },

            save() {
                if (this.preferredMethodSelected) {
                    const value = this.preferredMethodSelected === 'none' ? null : this.preferredMethodSelected;

                    this.update({ communication_preference: value });
                }

                this.close();
            },

            update(data) {
                this.$emit('update', data);
            },
        },
    };
</script>
