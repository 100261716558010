<template>
    <div
        class="wrapper relative flex flex-grow items-stretch"
        :class="[positioningClassName, {
            '-ml-2.5': animated,
            'text-gray-500': disabled,
        }]"
    >
        <date-time-picker
            class="relative flex flex-grow items-stretch transition-all duration-150 !shadow-none"
            :date-only="dateOnly"
            :disabled="disabled"
            :end-date="endDate"
            :initial-focus="initialFocus"
            input-class="
                    h-full min-h-0 px-2.5 py-1.5 rounded-sm placeholder-gray-500 bg-transparent truncate
                    transition duration-150
                "
            :picker-position="pickerPosition"
            :placeholder="placeholder"
            :required="required"
            :show-icon="showIcon"
            :start-date="startDate"
            :start-view="startView"
            :value="value"
            :white="white"
            @input="$emit('input', $event)"
        />
    </div>
</template>

<script>
    import DateTimePicker from '@/components/inputs/DateTimePicker.vue';

    export default {
        components: { DateTimePicker },

        props: {
            animated: {
                type: Boolean,
                default: true,
            },
            dateOnly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            endDate: {
                type: String,
                default: '',
            },
            initialFocus: {
                type: Boolean,
                default: false,
            },
            pickerPosition: {
                type: String,
                default: 'bottom-right',
            },
            placeholder: {
                type: String,
                default: '---',
            },
            required: {
                type: Boolean,
                default: false,
            },
            showIcon: {
                type: Boolean,
                default: false,
            },
            startDate: {
                type: String,
                default: '',
            },
            startView: {
                type: Number,
                default: 2,
            },
            value: {
                type: String,
                default: '',
            },
            white: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            positioningClassName() {
                return [
                    'transition-all duration-150',
                    { 'focus-within:ml-0': !this.disabled },
                ];
            },
        },
    };
</script>

<style scoped>
    .wrapper >>> input,
    .wrapper >>> button {
        @apply bg-white bg-opacity-0 border border-gray-300 border-opacity-0 ring-1 ring-gray-200 ring-opacity-0;
    }

    .wrapper >>> button {
        @apply border-l-0;
    }

    .wrapper >>> input:is(:focus,:hover,:focus-within) {
        @apply border-opacity-100;
    }

    .wrapper >>> input:is(:focus-within) {
        @apply ring-blue-500 ring-opacity-100 border-blue-500 border-opacity-100;
    }

    .wrapper >>> button:is(:focus,:hover,:focus-within) {
        @apply bg-blue-500 text-white border-blue-500 border-opacity-100
    }

    .wrapper >>> input:is(:focus,:hover,:focus-within) ~ button {
        @apply border-opacity-100;
    }
</style>
