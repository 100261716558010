<template>
    <div class="flex flex-col bg-white rounded-lg shadow-xl">
        <slot name="header" />
        <slot name="content" />
        <slot name="footer" />
    </div>
</template>

<script>
    export default {};
</script>
