import Enum from './Enum.js';

export default class EquityType extends Enum {
    static get entries() {
        return {
            xclean: 'xclean',
            clean: 'clean',
            average: 'average',
            rough: 'rough',
        };
    }

    static get XCLEAN() {
        return this.entries.xclean;
    }

    static get CLEAN() {
        return this.entries.clean;
    }

    static get AVERAGE() {
        return this.entries.average;
    }

    static get ROUGH() {
        return this.entries.rough;
    }
}
