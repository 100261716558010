<template>
    <box>
        <template #header>
            <box-header>
                {{ $t('clientCard.boxes.notes.title') }}

                <template #right>
                    <box-header-icon
                        :disabled="$wait.is('fetching.notes')"
                        :icon-class-name="{ 'spin-inverse': $wait.is('fetching.notes') }"
                        :icon-name="$icons.loading"
                        :indicator="refreshNeeded"
                        indicator-type="warning"
                        :tooltip="$t('dashboards.refresh')"
                        @click="refreshItems"
                    />

                    <box-header-icon
                        icon-name="regular/filter-1"
                        :indicator="hasFiltersApplied"
                        :tooltip="$t('dashboards.filter')"
                        v-if="hasFilters"
                    >
                        <template #popoverContent="{ closePopover }">
                            <filters
                                :filters="filters"
                                @apply="applyFilters($event, closePopover)"
                                @reset="clearFilters(closePopover)"
                            />
                        </template>
                    </box-header-icon>
                </template>
            </box-header>
        </template>

        <template #content>
            <note-input
                :account="account"
                :note-action="noteAction"
                :context-note="contextNoteData.note"
                ref="noteInput"
                @cancel="clearContextNote"
                @submit="onSubmit"
                v-if="!readonly"
            />

            <div
                class="relative flex flex-col flex-grow"
                :class="{
                    'overflow-hidden': !notes.length,
                    'overflow-y-auto' : notes.length,
                    'max-h-main-view xl:max-h-full': notes.length && !showListLoading
                }"
                ref="listWrapper"
            >
                <transition
                    enter-active-class="transition duration-150 transform"
                    enter-class="opacity-0 -translate-y-2"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition duration-150 transform"
                    leave-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 translate-y-2"
                    mode="out-in"
                >
                    <note-list
                        :class="{ 'xl:inset-0 xl:absolute': !showListLoading }"
                        :account="account"
                        :context-note-data="contextNoteData"
                        :note-type-filter="noteTypeFilter"
                        :notes="notes"
                        :readonly="readonly"
                        :show-list-loading="showListLoading"
                        :show-more-items-loading="showMoreItemsLoading"
                        @trigger-deleting="triggerDeleting"
                        @trigger-editing="triggerEditing"
                        @trigger-replying="triggerReplying"
                        v-if="notes.length || showListLoading"
                    />
                    <empty-state
                        class="pb-6"
                        :description="emptyStateDescription"
                        :title="$t('clientCard.emptyState.noteTitle')"
                        svg="note-blank-2"
                        v-else
                    />
                </transition>
            </div>

            <activix-confirm-modal
                type="error"
                :title="$t('general.areYouSure')"
                :opened.sync="modals.deletion.opened"
                :content="confirmDeletionMessage"
                @approve="onDeleteNote"
                @deny="resetDeleteConfirmationModal"
            />
        </template>
    </box>
</template>

<script>
    import Box from '@/components/presentational/boxes/Box';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon';
    import EmptyState from '@/components/EmptyState';
    import Filters from '@/components/container/Filters';
    import NoteInput from '@/components/container/lead/notes/NoteInput';
    import NoteList from '@/components/container/lead/notes/NoteList';

    import Account from '@/entities/Account';
    import NoteAction from '@/entities/NoteAction';

    export default {
        components: {
            Box,
            BoxHeader,
            BoxHeaderIcon,
            EmptyState,
            Filters,
            NoteInput,
            NoteList,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            clearContext: {
                type: Boolean,
                default: false,
            },
            filters: {
                type: Object,
                default: () => ({}),
            },
            filterValues: {
                type: Object,
                default: () => ({}),
            },
            initialFetchCompleted: {
                type: Boolean,
                required: true,
            },
            notes: {
                type: Array,
                required: true,
            },
            pagination: {
                type: Object,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                contextNoteData: {
                    action: null,
                    note: null,
                    rootNoteId: null,
                },
                modals: {
                    deletion: {
                        note: null,
                        opened: false,
                        rootNoteId: null,
                    },
                },
                refreshNeeded: false,
            };
        },

        computed: {
            canLoadMoreNotes() {
                return (this.pagination.perPage * this.pagination.currentPage) < this.pagination.totalItemsCount;
            },

            confirmDeletionMessage() {
                const { note } = this.modals.deletion;

                if (!note) {
                    return '';
                }

                const childCount = this.getNoteChildCount([note]);

                if (childCount === 1) {
                    return this.$t('clientCard.boxes.notes.deleteConfirmationSingleChild');
                }

                if (childCount > 1) {
                    return this.$t('clientCard.boxes.notes.deleteConfirmationMultipleChildren', [childCount]);
                }

                return this.$t('clientCard.noteDeleteMessage');
            },

            contextRootNoteId() {
                return this.contextNoteData.rootNoteId;
            },

            emptyStateDescription() {
                if (this.hasFiltersApplied) {
                    return this.$t('clientCard.emptyState.noteNoMatchDescription');
                }

                return this.$t('clientCard.emptyState.noteDescription');
            },

            hasFilters() {
                return Object.keys(this.filters).length > 0;
            },

            hasFiltersApplied() {
                return Object.entries(this.filterValues).some(([, values]) => values.length > 0);
            },

            isAtFirstPage() {
                return this.pagination.currentPage === 1;
            },

            noteAction() {
                return this.contextNoteData.action || NoteAction.CREATE;
            },

            noteTypeFilter() {
                return this.filterValues.noteType || [];
            },

            showListLoading() {
                return !this.initialFetchCompleted;
            },

            showMoreItemsLoading() {
                return !this.showListLoading &&
                    (
                        this.canLoadMoreNotes ||
                        (this.$wait.is('fetching.notes') && !this.isAtFirstPage)
                    );
            },
        },

        watch: {
            clearContext() {
                if (this.clearContext) {
                    this.clearContextNote();

                    this.$emit('update:clear-context', false);
                }
            },
        },

        methods: {
            applyFilters(selectedFilters, closeFilterMenu) {
                closeFilterMenu();

                this.$emit('apply-filters', {
                    ...this.filterValues,
                    ...selectedFilters,
                });

                this.refreshNeeded = false;

                this.clearContextNote();
            },

            clearContextNote() {
                this.contextNoteData.action = null;
                this.contextNoteData.note = null;
                this.contextNoteData.rootNoteId = null;
            },

            clearFilters(closeFilterMenu) {
                closeFilterMenu();

                this.$emit('clear-filters');

                this.refreshNeeded = false;

                this.clearContextNote();
            },

            onDeleteNote() {
                const { note, rootNoteId } = this.modals.deletion;

                this.resetDeleteConfirmationModal();

                this.$emit('delete-note', { note, rootNoteId });

                this.clearContextNote();
            },

            getNoteChildCount(notes) {
                return notes.reduce((childCount, note) => {
                    return childCount + note.children.length + this.getNoteChildCount(note.children);
                }, 0);
            },

            infiniteLoadingHandler() {
                if (!this.canLoadMoreNotes || this.$wait.is('fetching.notes')) {
                    return;
                }

                const { clientHeight, scrollHeight, scrollTop } = this.$refs.listWrapper;

                if (scrollTop + clientHeight >= scrollHeight - 100) {
                    this.$emit('fetch-next-page');
                }
            },

            initContextNote(note, rootNoteId, action) {
                this.contextNoteData.note = note;
                this.contextNoteData.rootNoteId = rootNoteId;
                this.contextNoteData.action = action;

                this.$refs.noteInput.focus();
            },

            onRefreshNeeded() {
                this.refreshNeeded = true;
            },

            onSubmit(note) {
                if (note.key) {
                    this.$emit('add-audio-note', { note, rootNoteId: this.contextRootNoteId });
                } else if (note.id) {
                    this.$emit('update-note', { note, rootNoteId: this.contextRootNoteId });
                } else {
                    this.$emit('add-note', { note, rootNoteId: this.contextRootNoteId });
                }

                this.clearContextNote();
            },

            refreshItems() {
                this.refreshNeeded = false;

                this.$emit('refresh-items');

                this.clearContextNote();
            },

            resetDeleteConfirmationModal() {
                this.modals.deletion.note = null;
                this.modals.deletion.rootNoteId = null;
            },

            triggerDeleting({ note, rootNoteId }) {
                this.modals.deletion.note = note;
                this.modals.deletion.rootNoteId = rootNoteId;
                this.modals.deletion.opened = true;
            },

            triggerEditing({ note, rootNoteId }) {
                this.initContextNote(note, rootNoteId, NoteAction.EDIT);
            },

            triggerReplying({ note, rootNoteId }) {
                this.initContextNote(note, rootNoteId, NoteAction.REPLY);
            },
        },

        mounted() {
            this.$eventBus.$on('refresh-comments', this.onRefreshNeeded);

            this.$refs.listWrapper.addEventListener('scroll', this.infiniteLoadingHandler);
        },

        beforeDestroy() {
            this.$eventBus.$off('refresh-comments', this.onRefreshNeeded);

            this.$refs.listWrapper.removeEventListener('scroll', this.infiniteLoadingHandler);
        },
    };
</script>
