<template>
    <activix-modal
        size="lg"
        :opened="opened"
        @close="close"
        @open="onOpen"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.selectCorrectVersion') }}
            </h4>
        </template>

        <template slot="body">
            <div class="flex flex-wrap justify-center px-16 -mx-8 my-3 | lg:-mx-3 lg:px-1">
                <div
                    class="w-full my-6 | lg:w-1/3"
                    :key="version.uvc"
                    v-for="(version, index) in sortedVersions"
                >
                    <div
                        class="ring-1 rounded-lg transition duration-300 py-7 shadow-sm mx-0 | lg:mx-3 | hover:shadow-md hover:ring-blue-300 hover:shadow-md"
                        :class="[isSelected(version) ? 'card-value-selected' : 'card-value']"
                    >
                        <div class="px-6 relative">
                            <div class="absolute flex items-center -left-3 -top-10 px-2 py-1 bg-blue-500 text-sm text-white rounded-full shadow" v-if="index === 0">
                                <span v-text="$t('clientCard.bestMatch')" />
                                <icon class="text-lg ml-2" name="regular/vip-crown-queen-2" />
                            </div>
                            <div>
                                <div class="flex justify-between">
                                    <div class="w-5/12 text-lg leading-6 font-medium text-blue-500 truncate">
                                        <span class="font-extrabold" v-text="version.model" />
                                    </div>
                                    <div class="w-7/12 text-gray-800 text-right leading-6" v-text="version.series" />
                                </div>
                                <div class="flex justify-between">
                                    <div>
                                        <div class="text-3xl font-extrabold text-blue-500" v-text="toMoney(version.msrp)" />
                                        <div class="text-xs text-gray-600 uppercase">
                                            ({{ $t('clientCard.vehicles.msrp') }})
                                        </div>
                                    </div>
                                    <div class="text-gray-800 text-right leading-6" v-text="version.style" />
                                </div>
                            </div>
                        </div>
                        <div class="pt-12 pb-8 px-6 space-y-4 text-sm text-gray-600">
                            <span class="flex justify-between">
                                {{ $t('clientCard.vehicles.transmission') }}: <span class="ml-2 text-md font-bold text-gray-800" v-text="version.transmission" />
                            </span>

                            <span class="flex justify-between">
                                {{ $t('clientCard.vehicles.engine') }}: <span class="ml-2 text-md font-bold text-gray-800" v-text="version.engine_description" />
                            </span>

                            <span class="flex justify-between">
                                {{ $t('clientCard.vehicles.horsepower') }}: <span class="ml-2 text-md font-bold text-gray-800" v-text="version.base_hp" />
                            </span>

                            <span class="flex justify-between">
                                {{ $t('clientCard.vehicles.torque') }}: <span class="ml-2 text-md font-bold text-gray-800" v-text="version.torque" />
                            </span>
                        </div>
                        <activix-button
                            class="w-3/4 m-auto flex rounded"
                            type="primary"
                            :disabled="isSelected(version)"
                            @click="select(version)"
                        >
                            {{ isSelected(version) ? $t('multiselect.selected') : $t('addLead.select') }}
                        </activix-button>
                    </div>
                </div>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { cloneDeep } from 'lodash-es';
    import { mapState } from 'pinia';
    import VehicleVinMatchType from '../../entities/VehicleVinMatchType.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            vehicle: null,
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                versions: [],
                selected: null,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useContextStore, {
                equityBase: store => store.account.renewal_alert_options.equity_base,
            }),

            sortedVersions() {
                if (!this.versions) {
                    return [];
                }

                return cloneDeep(this.versions).sort((a, b) => {
                    return b.match - a.match;
                });
            },
        },

        methods: {
            isSelected(version) {
                return this.selected == version.uvc;
            },

            close() {
                this.$emit('update:opened', false);
            },

            loadVersions() {
                this.versions = this.vehicle.vehicle_value.vehicle_vin.partial_matches;
                if (this.vehicle.vehicle_value.vehicle_vin.match != VehicleVinMatchType.PARTIAL) {
                    this.selected = this.vehicle.vehicle_value.vehicle_vin.uvc;
                }
            },

            select(version) {
                this.selected = version.uvc;
                setTimeout(() => {
                    this.$emit('approve', this.selected);
                    this.close();
                }, 300);
            },

            onOpen() {
                this.loadVersions();
            },

            onClosed() {
                this.versions = [];
            },
        },
    };
</script>

<style>
    .card-value {
        @apply ring-gray-300;
        background: linear-gradient(180deg, #F9F9F9, #F9F9F9 34%, #ffffff 0)
    }

    .card-value-selected {
        @apply ring-blue-300;
        background: linear-gradient(180deg, #F9F9F9, #F9F9F9 34%, #ffffff 0);
        background-size: 100% 340%;
        animation: card-selected 0.5s ease;
    }

    @keyframes card-selected {
        from {
            background-position-y: 34%;
        }
        to {
            background-position-y: 0;
        }
    }
</style>
