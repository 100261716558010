<template>
    <div
        class="relative flex flex-grow items-stretch max-w-full"
        :class="[positioningClassName, {
            '-ml-2.5': animated && !opened,
            'text-gray-500': disabled
        }]"
        @keydown.capture.enter="onEnter"
        @keydown.capture.stop.esc="onEsc"
    >
        <activix-select
            class="flex-grow max-w-full"
            :class="[positioningClassName, { '-ml-px': animated && !opened }]"
            trigger-padding-class-name="px-2.5 py-1.5"
            :invisible="true"
            :disabled="disabled"
            :value-key="identifier"
            :label-key="label"
            :multiple="multiple"
            :options="internalOptions"
            :placeholder="placeholder"
            :filterable="searchable"
            :taggable="taggable"
            :nullable="nullable"
            :select-all="selectAll"
            :focus-on-enter="false"
            ref="select"
            @open="opened = true"
            @close="opened = false"
            @blur="onBlur"
            @input="onInput"
            v-model="internalValue"
        />
    </div>
</template>

<script>
    import { ActivixSelect } from '@activix/ui';

    export default {
        components: {
            ActivixSelect,
        },

        props: {
            animated: {
                type: Boolean,
                default: true,
            },
            appendMissingValue: {
                default: false,
                type: Boolean,
            },
            disabled: {
                default: false,
                type: Boolean,
            },
            identifier: {
                default: 'value',
                type: String,
            },
            label: {
                default: 'text',
                type: String,
            },
            multiple: {
                default: false,
                type: Boolean,
            },
            nullable: {
                default: false,
                type: Boolean,
            },
            options: {
                required: true,
                type: Array,
            },
            placeholder: {
                default: '---',
                type: String,
            },
            searchable: {
                default: false,
                type: Boolean,
            },
            selectAll: {
                default: false,
                type: Boolean,
            },
            taggable: {
                default: false,
                type: Boolean,
            },
            value: {
                required: true,
                type: null,
            },
        },

        data: (vm) => ({
            opened: false,
            internalValue: vm.value,
            cancelling: false,
        }),

        computed: {
            internalOptions() {
                if (!this.appendMissingValue || !this.value) {
                    return this.options;
                }

                const valueAlreadyExists = this.options.some(
                    option => option[this.identifier]?.toString() === this.value?.toString(),
                );

                if (valueAlreadyExists) {
                    return this.options;
                }

                const options = [...this.options];
                const pos = options.some(option => !option[this.identifier]) ? 1 : 0;

                options.splice(pos, 0, {
                    [this.identifier]: this.value,
                    [this.label]: this.value,
                });

                return options;
            },

            positioningClassName() {
                return [
                    'transition-all duration-150',
                    { 'focus-within:ml-0': !this.disabled },
                ];
            },
        },

        watch: {
            value(newValue) {
                if (newValue !== this.internalValue) {
                    this.internalValue = newValue;
                }
            },
        },

        methods: {
            onBlur() {
                if (!this.multiple) {
                    this.$refs.select.blur();
                    return;
                }

                this.update();
            },

            onInput() {
                if (this.multiple) {
                    return;
                }

                this.update();
            },

            onEnter(event) {
                if (this.multiple) {
                    // Prevent selection for multiselect only.
                    event.stopPropagation();
                }

                this.$refs.select.blur();
            },

            onEsc() {
                this.cancelling = true;
                this.$refs.select.blur();
            },

            open() {
                this.$refs.select.open();
            },

            update() {
                if (this.cancelling) {
                    this.internalValue = this.value;
                    this.cancelling = false;
                    return;
                }

                if (this.disabled || this.value === this.internalValue) {
                    return;
                }

                this.$emit('input', this.internalValue);
            },
        },
    };
</script>
