<template>
    <div
        class="flex text-sm text-gray-800 | transition cursor-pointer hover:text-gray-600"
        v-popover:replyPreview
        v-if="showWidget"
    >
        <icon name="regular/email-action-reply" />

        <el-popover
            placement="top"
            popper-class="text-left"
            trigger="click"
            ref="replyPreview"
        >
            <div class="flex flex-col max-w-xs p-3 space-y-1.5 rounded-lg overflow-hidden">
                <div class="flex justify-between gap-2 items-center">
                    <span class="font-semibold" v-text="parentNoteOwnerUsername" />
                    <span class="text-sm text-gray-600" v-text="parentNoteDateTime" />
                </div>

                <p class="mb-0 line-clamp-2 text-sm" v-text="parentNote.content" />
            </div>
        </el-popover>
    </div>
</template>

<script>
    import Comment from '@/entities/Comment';
    import User from '@/entities/User.js';

    export default {
        props: {
            depth: {
                type: Number,
                required: true,
            },
            note: {
                type: Comment,
                required: true,
            },
            parentNote: {
                type: Comment,
                required: true,
            },
        },

        computed: {
            parentNoteDateTime() {
                const createdAt = as_locale(this.parentNote.created_at, 'created_at');

                if (createdAt.isSameDay(now())) {
                    return createdAt.fromNow()[0].toUpperCase() + createdAt.fromNow().slice(1);
                }

                return createdAt.humanShort(true);
            },

            parentNoteOwnerUsername() {
                return new User(this.parentNote.user).fullName;
            },

            showWidget() {
                return this.depth >= 1 && this.parentNote.isNotEmpty();
            },
        },
    };
</script>
