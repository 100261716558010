<template>
    <div
        class="input-group group
            relative flex items-stretch rounded-sm border-0 shadow-none bg-white bg-opacity-0
            transform transition-all duration-150 py-2"
    >
        <activix-tooltip :content="ratingComment">
            <span
                class="ratings"
                :class="{ readonly: readonly }"
            >
                <template v-for="x in range">
                    <label
                        class="m-0 cursor-pointer"
                        :class="{
                            'cursor-default': readonly,
                            'text-blue-500': rating >= x,
                            'text-gray-500': rating < x,
                        }"
                        :for="`${name}${x}`"
                        :key="`l${x}`"
                    >
                        <icon
                            class="text-xl"
                            name="bold/rating-star"
                        />
                    </label>

                    <input
                        :id="`${name}${x}`"
                        type="radio"
                        :checked="rating === x"
                        :disabled="readonly"
                        :name="name"
                        :value="x"
                        :key="`i${x}`"
                        @click="onChange(x)"
                    />
                </template>
            </span>
        </activix-tooltip>

        <rating-modal
            :rating="rating"
            :rating-comment="ratingComment"
            :opened="modals.ratingComment.opened"
            @close="modals.ratingComment.opened = false"
            @update-lead="updateLead"
        />
    </div>
</template>

<script>
    import { range, uniqueId } from 'lodash-es';

    import RatingModal from '@/components/modals/RatingModal';

    export default {
        components: { RatingModal },

        props: {
            max: {
                type: Number,
                default: 5,
            },
            rating: {
                required: true,
                validator: value => typeof value === 'number' || value === null,
            },
            ratingComment: {
                required: true,
                validator: value => typeof value === 'string' || value === null,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                modals: {
                    ratingComment: {
                        opened: false,
                    },
                },
            };
        },

        computed: {
            name() {
                return uniqueId('rating');
            },

            range() {
                return range(1, this.max + 1);
            },
        },

        methods: {
            onChange(value) {
                if (this.rating !== value) {
                    this.updateLead({ rating: value });
                }

                this.modals.ratingComment.opened = true;
            },

            updateLead(data, options = {}) {
                this.$emit('update-lead', { data, options });
            },
        },
    };
</script>

<style scoped>
    .ratings input {
        display: none;
    }

    .ratings label:not(:first-of-type) {
        @apply px-1;
    }

    .ratings label:first-of-type {
        @apply pr-1;
    }

    .ratings:not(.readonly):hover label {
        @apply text-blue-500;
    }

    .ratings:not(.readonly) label:hover ~ label {
        @apply text-gray-500;
    }
</style>
