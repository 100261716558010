<template>
    <div
        class="
            absolute -mt-2 -mr-2 top-0 right-0 pointer-events-none opacity-0
            group-hover:pointer-events-auto group-hover:opacity-100
            transition duration-300
        "
    >
        <el-dropdown
            placement="bottom-start"
            trigger="click"
        >
            <button
                class="
                    flex items-center justify-center h-6 w-6 rounded-full bg-white shadow text-sm link-gray-light
                    hover:bg-gray-100
                    transition duration-300
                "
            >
                <icon name="bold/navigation-menu-vertical" />
            </button>

            <el-dropdown-menu class="divide-y divide-gray-200 p-0" slot="dropdown">
                <el-dropdown-item
                    class="py-1 group"
                    :disabled="deleteActionDisabled"
                    @click.native="modals.deleteVehicle.opened = true"
                    v-if="isDeletable"
                >
                    <div
                        class="flex items-center text-gray-700"
                        :class="{
                            'group-hover:text-red-500': !deleteActionDisabled,
                            'text-gray-500': deleteActionDisabled
                        }"
                    >
                        <icon class="mr-2 text-lg" :name="$icons.trash" />
                        <span>{{ $t('general.delete') }}</span>
                    </div>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <delete-vehicle
            :opened="modals.deleteVehicle.opened"
            :vehicle-id="vehicle.id"
            @close="modals.deleteVehicle.opened = false"
            @delete-vehicle="$emit('delete-vehicle', $event)"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import DeleteVehicle from '@/components/modals/DeleteVehicle.vue';

    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: { DeleteVehicle },

        props: {
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data: () => ({
            modals: {
                deleteVehicle: {
                    opened: false,
                },
            },
        }),

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            deleteActionDisabled() {
                return !!(this.vehicle.sold || this.vehicle.supplierWithRelatedId);
            },

            isDeletable() {
                return !this.guest;
            },
        },
    };
</script>
