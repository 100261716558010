<template>
    <div class="relative flex flex-1 flex-col justify-center items-center text-gray-700 | last:rounded-tr-lg">
        <box-header-icon
            :class="{ 'rounded-tr-lg md:rounded-none xl:rounded-tr-lg 3xl:rounded-none': $scopedSlots.actions }"
            icon-name="regular/navigation-menu-horizontal"
            icon-size-class-name="text-2xl"
            :tooltip="$t('clientCard.boxes.general.moreOptions')"
        >
            <template #popoverContent="{ closePopover }">
                <slot name="actions" :close-menu="closePopover" />

                <div class="flex flex-col">
                    <button-icon
                        button-class-name="px-4 py-3 !justify-start hover:!bg-gray-100"
                        icon-name="regular/alarm-bell"
                        @click="openModal('subscription', closePopover)"
                    >
                        <template #after-icon>
                            <span v-text="$t('general.optOut')" />
                        </template>
                    </button-icon>

                    <button-icon
                        button-class-name="px-4 py-3 !justify-start hover:!bg-gray-100"
                        icon-name="regular/alarm-bell-sleep"
                        @click="openModal('dnd', closePopover)"
                    >
                        <template #after-icon>
                            <span v-text="$t('clientCard.doNotDisturb')" />
                        </template>
                    </button-icon>

                    <button-icon
                        button-class-name="px-4 py-3 !justify-start hover:!bg-gray-100"
                        icon-name="regular/wifi-signal-4"
                        @click="openModal('preference', closePopover)"
                    >
                        <template #after-icon>
                            <span v-text="$t('clientCard.boxes.general.contactPreference')" />
                        </template>
                    </button-icon>

                    <button-icon
                        button-class-name="px-4 py-3 !justify-start hover:!bg-gray-100"
                        icon-name="regular/check-shield"
                        icon-class-name="text-green-500"
                        @click="openModal('consent', closePopover)"
                        v-if="$feature.isEnabled('casl_compliance')"
                    >
                        <template #after-icon>
                            <span v-text="$t('consent.consent')" />
                        </template>
                    </button-icon>
                </div>
            </template>
        </box-header-icon>

        <slot name="modals" />
    </div>
</template>

<script>
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon';
    import ButtonIcon from '@/components/presentational/ButtonIcon';

    export default {
        components: { BoxHeaderIcon, ButtonIcon },

        methods: {
            openModal(modalName, closePopover) {
                if (!modalName) {
                    return;
                }

                closePopover();

                this.$emit('open-modal', modalName);
            },
        },
    };
</script>
