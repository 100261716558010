<template>
    <activix-confirm-modal
        type="error"
        :title="$t('clientCard.deleteVehicleConfirm')"
        :content="$t('clientCard.deleteVehicleSubConfirm')"
        :opened="opened"
        @approve="$emit('delete-vehicle', vehicleId)"
        @close="$emit('close')"
    />
</template>

<script>
    export default {
        props: {
            vehicleId: {
                type: Number,
                required: true,
            },
            opened: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
