<template>
    <span>
        {{ output }}
    </span>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate';

    export default {
        props: {
            date: {
                type: [String, ActivixDate],
                required: true,
            },
            prefix: {
                type: String,
                default: '',
            },
        },

        computed: {
            output() {
                if (!this.formattedDistance) {
                    return '';
                }

                return `${this.prefix} ${this.formattedDistance}`.trim();
            },

            currentDate() {
                if (this.date instanceof ActivixDate) {
                    return this.date;
                }

                return new ActivixDate(this.date);
            },

            formattedDistance() {
                return this.currentDate.formatDistanceToNow();
            },
        },
    };
</script>
