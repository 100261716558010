<template>
    <el-popover
        popper-class="bg-white border-0 rounded-lg"
        placement="right"
        trigger="hover"
        :close-delay="0"
        :disabled="isExpanded"
    >
        <template #reference>
            <div class="flex-1 flex items-center p-6 gap-3 overflow-hidden">
                <figure class="flex items-center justify-center w-18 h-18 rounded-full shadow-md bg-white">
                    <activix-avatar
                        class="flex-shrink-0 border border-gray-300 rounded-full text-gray-700 bg-gray-200"
                        :size="52"
                        :username="customerName"
                    />
                </figure>

                <associated-leads-customer-info
                    :customer-name="customerName"
                    :customer-since-year="customerSinceYear"
                    v-if="isExpanded"
                />
            </div>
        </template>

        <associated-leads-customer-info
            class="p-6"
            :customer-name="customerName"
            :customer-since-year="customerSinceYear"
        />
    </el-popover>
</template>

<script>
    import AssociatedLeadsCustomerInfo from '@/components/container/lead/AssociatedLeadsCustomerInfo';

    export default {
        components: { AssociatedLeadsCustomerInfo },

        props: {
            customerName: {
                type: String,
                required: true,
            },
            customerSinceYear: {
                validator: prop => typeof prop === 'number' || prop === null,
                required: true,
            },
            isExpanded: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
