import Comment from '@/entities/Comment';
import CommentType from '@/entities/CommentType';

export default {
    data() {
        return {
            clearContext: false,
            filterValues: {},
            initialFetchCompleted: false,
            notes: [],
            pagination: {
                currentPage: 1,
                perPage: 25,
                totalItemsCount: 0,
            },
        };
    },

    computed: {
        legacyNoteTypeFilter() {
            let filterTypeId = this.$ls.get('commentsFilter', null);

            if (CommentType.exists(filterTypeId)) {
                return filterTypeId;
            }

            switch (filterTypeId) {
                case 'all': filterTypeId = CommentType.ALL; break;
                case 'attachment': filterTypeId = CommentType.ATTACHMENT; break;
                case 'audio': filterTypeId = CommentType.AUDIO; break;
                case 'comment': filterTypeId = CommentType.STRING; break;
            }

            return filterTypeId;
        },
    },

    methods: {
        applyFilters(filters) {
            if (Number.isInteger(filters.noteType)) {
                filters.noteType = [filters.noteType];
            }

            this.$set(this, 'filterValues', {
                ...filters,
                noteType: filters.noteType || [],
            });

            this.persistFilters();
            this.initialFetchCompleted = false;
            this.resetPagination();
            this.fetchCurrentPage(true);
        },

        clearFilters() {
            this.applyFilters({});
        },

        async fetchCurrentPage(replaceItems = false) {
            await this.fetchPage(this.pagination.currentPage, replaceItems);
        },

        async fetchFirstPage(replaceItems = false) {
            await this.fetchPage(1, replaceItems);
        },

        async fetchNextPage(replaceItems = false) {
            await this.fetchPage(++this.pagination.currentPage, replaceItems);
        },

        getNoteIndexForItsContext(noteId, rootNoteId) {
            const rootNoteIndex = this.notes.findIndex(note => note.id === rootNoteId);

            if (rootNoteIndex === -1) {
                return {};
            }

            if (noteId === rootNoteId) {
                return {
                    context: this.notes,
                    index: rootNoteIndex,
                };
            }

            const parentNote = this.getParentNote(noteId, this.notes[rootNoteIndex]);

            if (!parentNote) {
                return {};
            }

            const index = parentNote.children.findIndex(childNote => childNote.id === noteId);

            if (index === -1) {
                return {};
            }

            return {
                context: parentNote.children,
                index,
            };
        },

        getNotePage(noteId) {
            const noteIndex = this.notes.findIndex(note => note.id === noteId);

            if (noteIndex === -1) {
                return null;
            }

            const notePosition = noteIndex + 1;

            return Math.ceil(notePosition / this.pagination.perPage);
        },

        getNoteChildCount(notes) {
            return notes.reduce((childCount, note) => {
                return childCount + note.children.length + this.getNoteChildCount(note.children);
            }, 0);
        },

        getParentNote(noteId, ancestorNote) {
            if (!ancestorNote.children.length) {
                return null;
            }

            const isParent = ancestorNote.children.some(childNote => childNote.id === noteId);

            if (isParent) {
                return ancestorNote;
            }

            for (const childNote of ancestorNote.children) {
                const parentNote = this.getParentNote(noteId, childNote);

                if (parentNote) {
                    return parentNote;
                }
            }

            return null;
        },

        initFilters() {
            const savedFilters = this.$ls.get(this.filtersStorageKey, null);

            if (savedFilters) {
                this.applyFilters(savedFilters);
            } else if (this.legacyNoteTypeFilter) {
                this.applyFilters({ noteType: [this.legacyNoteTypeFilter] });
            } else {
                this.applyFilters({});
            }
        },

        mergeFetchedNotes(fetchedNotes, replaceItems = false) {
            if (!this.notes.length || replaceItems) {
                this.notes = fetchedNotes.map(note => new Comment(note));

                return;
            }

            fetchedNotes.forEach(fetchedNote => {
                const fetchedNoteObject = new Comment({ ...fetchedNote });

                const existingNoteIndex = this.notes.findIndex(existingNote => existingNote.id === fetchedNote.id);

                if (existingNoteIndex !== -1) {
                    this.notes.splice(existingNoteIndex, 1, fetchedNoteObject);

                    return;
                }

                const previousNoteIndex = this.notes.findIndex(previousNote => previousNote.created_at < fetchedNote.created_at);

                if (previousNoteIndex === 0) {
                    this.notes.splice(0, 0, fetchedNoteObject);

                    return;
                }

                if (previousNoteIndex === -1) {
                    this.notes.push(fetchedNoteObject);

                    return;
                }

                this.notes.splice(previousNoteIndex - 1, 0, fetchedNoteObject);
            });
        },

        async onNoteAdded(rootNoteId) {
            if (rootNoteId) {
                await this.reloadNotePage(rootNoteId);
            } else {
                await this.fetchFirstPage();
            }
        },

        persistFilters() {
            this.$ls.set(this.filtersStorageKey, this.filterValues);
        },

        async refreshItems() {
            this.resetPagination();
            await this.fetchCurrentPage(true);
        },

        async reloadNotePage(noteId) {
            const notePage = this.getNotePage(noteId);

            if (!notePage) {
                return;
            }

            await this.fetchPage(notePage);
        },

        removeNoteFromList(noteId, rootNoteId) {
            const { context, index } = this.getNoteIndexForItsContext(noteId, rootNoteId);

            if (!context) {
                return;
            }

            context.splice(index, 1);
        },

        resetPagination() {
            this.pagination.currentPage = 1;
            this.pagination.totalItemsCount = 0;
        },

        triggerClearContext() {
            this.clearContext = true;
        },

        updateNoteFromList(noteId, rootNoteId, data) {
            const { context, index } = this.getNoteIndexForItsContext(noteId, rootNoteId);

            if (!context) {
                return;
            }

            context.splice(index, 1, new Comment({
                ...context[index],
                ...data,
            }));
        },
    },

    created() {
        this.$wait.start('fetching.notes');
    },

    mounted() {
        this.$eventBus.$on('update-comment', this.fetchFirstPage);

        this.initFilters();
    },

    beforeDestroy() {
        this.$eventBus.$off('update-comment', this.fetchFirstPage);
    },
};
