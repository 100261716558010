<template>
    <div class="relative overflow-hidden shadow-md rounded-b-lg bg-white | transition-height duration-300">
        <div class="border-t border-gray-200 p-6">
            <div class="flex flex-col items-start justify-between gap-3 | md:flex-row md:items-center">
                <h3 class="box-title" v-text="$t('clientCard.boxes.vehicles.details')" />

                <activix-pills class="hidden md:flex xl:hidden 2xl:flex" :pills="pills" v-model="activePill" />

                <activix-select
                    class="w-full | block md:hidden xl:block xl:w-auto 2xl:hidden"
                    :options="pills"
                    value-key="name"
                    v-model="activePill"
                />
            </div>

            <custom-input-list
                class="mt-6"
                :blocked-reason="activeTabConfig.blockedReason"
                :disabled="activeTabConfig.disabled || false"
                :section="customFieldsSection"
                :values="activeTabFieldsConfig"
                @input="updateVehicle"
                v-if="activePill === 'custom'"
            />

            <div class="flex flex-col gap-6 mt-6" v-else>
                <input-list
                    :column-count="groupConfig.columnCount"
                    :fields="groupConfig.fields"
                    :key="index"
                    v-for="(groupConfig, index) in activeTabFieldsConfig"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { ActivixSelect } from '@activix/ui';

    import CustomInputList from '@/components/presentational/input/CustomInputList.vue';
    import InputList from '@/components/container/input/InputList';

    import Account from '@/entities/Account';
    import ClientCardSelection from '@/entities/ClientCardSection.js';
    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            ActivixSelect,
            CustomInputList,
            InputList,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            tabsConfig: {
                type: Object,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data: () => ({
            activePill: null,
        }),

        computed: {
            activeTabFieldsConfig() {
                if (this.activePill === 'custom') {
                    return this.vehicle.custom_fields;
                }

                return this.activeTabConfig?.fieldsConfig;
            },

            activeTabConfig() {
                return this.tabsConfig[this.activePill];
            },

            customFieldsSection() {
                return this.vehicle.isWanted ? ClientCardSelection.VEHICLE_WANTED : ClientCardSelection.VEHICLE_EXCHANGE;
            },

            pills() {
                return Object.entries(this.tabsConfig).map(([key, tabConfig]) => ({
                    name: key,
                    label: tabConfig.label,
                }));
            },
        },

        methods: {
            updateVehicle({ data, options }) {
                this.$emit('update-vehicle', { id: this.vehicle.id, data, options });
            },
        },

        mounted() {
            this.activePill = this.pills[0].name;
        },
    };
</script>

<style scoped>
    .vehicle-pills >>> button:not([class*="hover:"]) {
        @apply text-gray-800 bg-gray-200 !important;
    }

    .vehicle-pills >>> button[class*="hover:"] {
        @apply text-gray-600 hover:text-gray-700 hover:bg-gray-150 !important;
    }
</style>
