<template>
    <div
        class="relative"
        @dragleave="dragAndDropping = false"
        @dragover.prevent="dragAndDropping = true"
        @drop.prevent="dropFile"
    >
        <div
            class="absolute z-20 inset-0 flex flex-col items-center justify-center border-dashed border-2 border-blue-500 bg-gray-100 rounded pointer-events-none"
            v-if="dragAndDropping"
        >
            <icon class="text-blue-500 text-2xl mb-1" name="regular/download-button" />
            <span>{{ $t('clientCard.dropAttachment') }}</span>
        </div>

        <slot />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dragAndDropping: false,
            };
        },

        methods: {
            dropFile(event) {
                this.dragAndDropping = false;

                this.$emit('file-dropped', event.dataTransfer.files);
            },
        },
    };
</script>
