<template>
    <input-list :column-count="columnCount" :fields="fieldsConfig" />
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store';

    import ActivixCheckbox from '@/components/elements/ActivixCheckbox.vue';
    import InputList from '@/components/container/input/InputList.vue';
    import InvisibleDateTimePicker from '@/components/container/input/InvisibleDateTimePicker.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput.vue';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';
    import InvisibleTextarea from '@/components/container/input/InvisibleTextarea.vue';

    import { parseCustomField } from '@/value-objects/CustomField.js';

    export default {
        components: { InputList },

        props: {
            blockedReason: {
                type: String,
            },
            columnCount: {
                type: Number,
                default: 2,
            },
            direction: {
                type: String,
                default: 'vertical',
                validator: value => ['horizontal', 'vertical'].includes(value),
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            section: {
                type: String,
                required: true,
            },
            values: {
                type: Array,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, { contextAccount: 'account' }),

            fields() {
                return this.contextAccount.getAllCustomFields()
                    .filter(field => field.visible && field.section === this.section)
                    .sort((a, b) => {
                        if (a.display === 'line' && b.display === 'box') {
                            return -1;
                        }

                        if (a.display === 'box' && b.display === 'line') {
                            return 1;
                        }

                        return a.order - b.order;
                    });
            },

            fieldsConfig() {
                return this.fields
                    .map((field, index) => {
                        const column = this.getFieldColumn(field, index);

                        return this.getFieldConfig(field, column);
                    });
            },

            gridFieldsCount() {
                return this.fields.filter(field => field.display === 'line').length;
            },
        },

        methods: {
            getComponentProps(component, field) {
                const rawValue = this.values.find(value => value.id === field.id)?.pivot?.value || [];
                const value = parseCustomField.toEdit(rawValue, field.type);

                const props = {
                    disabled: this.disabled,
                    value,
                };

                switch (component) {
                    case InvisibleTextarea:
                        return {
                            ...props,
                            rows: field.display === 'line' ? 1 : 3,
                        };

                    case InvisibleDateTimePicker:
                        return {
                            ...props,
                            class: 'h-9',
                            enableEmpty: true,
                            startView: 2,
                        };

                    case InvisibleMultiselect:
                        return {
                            ...props,
                            value: props.value.map(value => value.id),
                            class: 'h-9',
                            identifier: 'id',
                            label: 'label',
                            limitSelectedVisible: 1,
                            multiple: true,
                            options: this.getFieldSelectPickerOptions(field),
                            searchable: false,
                            taggable: true,
                        };

                    case InvisibleInput:
                        if (field.type === 'currency') {
                            return {
                                ...props,
                                class: 'h-9',
                                mask: 'money',
                            };
                        }

                        if (field.type === 'url') {
                            return {
                                ...props,
                                class: 'h-9',
                                buttonIcon: 'regular/hyperlink',
                                buttonTooltip: this.linkButtonTooltip(field),
                                buttonDisabled: !this.linkIsValid(this.getFieldValue(field)),
                            };
                        }

                        break;
                }

                return {
                    ...props,
                    class: 'h-9',
                };
            },

            getFieldColumn(field, index) {
                if (field.display === 'box') {
                    return 1;
                }

                if (this.direction === 'vertical') {
                    const itemsByCol = Math.ceil(this.gridFieldsCount / this.columnCount);
                    const colIndexThreshold = Array(this.columnCount)
                        .fill(0)
                        .map((_, colIndex) => itemsByCol + (colIndex * itemsByCol));

                    return colIndexThreshold.findIndex(threshold => index < threshold) + 1;
                }

                return (index % this.columnCount) + 1;
            },

            getFieldComponent(field) {
                switch (field.type) {
                    case 'array':
                        return InvisibleMultiselect;
                    case 'datetime':
                        return InvisibleDateTimePicker;
                    case 'boolean':
                        return ActivixCheckbox;
                    case 'textarea':
                        return InvisibleTextarea;
                    case 'currency':
                    default:
                        return InvisibleInput;
                }
            },

            getFieldConfig(field, column) {
                const component = this.getFieldComponent(field);

                const badges = [];

                if (field.parentName) {
                    badges.push({
                        text: this.$t('clientCard.group'),
                        tooltip: this.$t('clientCard.fieldFromGroup', [field.parentName]),
                    });
                }

                return {
                    column,
                    component,
                    display: field.display,
                    fixedHeight: false,
                    label: field.name,
                    badges,
                    blockedReason: this.blockedReason,
                    events: {
                        input: value => {
                            const formattedValue = parseCustomField.toSave(value, field.type);

                            this.$emit('input', {
                                data: {
                                    [field.field]: value,
                                },
                                options: {
                                    customField: {
                                        ...field,
                                        value: formattedValue,
                                    },
                                },
                            });
                        },
                        'button-click': () => {
                            if (field.type === 'url') {
                                this.goToLink(field);
                            }
                        },
                    },
                    props: this.getComponentProps(component, field),
                };
            },

            getFieldSelectPickerOptions(field) {
                return field.select_picker_options
                    .map(item => {
                        return {
                            id: item,
                            label: item,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
            },

            getFieldValue(field) {
                const rawValue = this.values.find(value => value.id === field.id)?.pivot?.value || [];

                return parseCustomField.toRead(rawValue, field.type);
            },

            linkIsValid(value) {
                return !!value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
            },

            linkButtonTooltip(field) {
                return this.linkIsValid(this.getFieldValue(field)) ? this.$t('general.goToLink') : this.$t('general.invalidLink');
            },

            goToLink(field) {
                if (this.linkIsValid(this.getFieldValue(field))) {
                    window.open(this.getFieldValue(field), '_blank');
                }
            },
        },
    };
</script>
