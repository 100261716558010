<template>
    <div class="flex">
        <div class="flex items-center">
            <activix-select
                :disabled="disabled"
                :filterable="true"
                :multiple="true"
                :options="contactableUsers"
                :same-width="false"
                label-key="text"
                value-key="id"
                :value="selectedItemIds"
                ref="alertUsers"
                @input="syncWithGroups"
            >
                <template #trigger="{ selected }">
                    <activix-tooltip :content="$t('clientCard.addCommentMentions')">
                        <activix-button class="rounded" type="white" :disabled="disabled">
                            <icon name="regular/single-neutral" />
                            <span class="pl-1 text-xs" v-if="selected.length > 0">{{ selected.length }}</span>
                        </activix-button>
                    </activix-tooltip>
                </template>
            </activix-select>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import { ActivixSelect } from '@activix/ui';
    import { uniqBy } from 'lodash-es';

    import Account from '@/entities/Account.js';

    export default {
        components: {
            ActivixSelect,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            selectedItemIds: {
                type: Array,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),

            activeOrNotifiedUsers() {
                return this.account.users
                    .filter(user => {
                        return user.active || this.selectedItemIds.some(notifiedUserId => notifiedUserId.id === user.id);
                    })
                    .map(user => ({
                        id: user.id,
                        text: this.getFullName(user),
                        cellphone: user.phones?.some(phone => phone.cellphone),
                        post_id: user.post_id,
                        role_id: user.role_id,
                    }));
            },

            contactableUsers() {
                const guestGroups = this.account.guest_groups.map(group => {
                    return {
                        id: `G${group.id}`,
                        isGroup: true,
                        text: `[${group.name}]`,
                        posts: group.posts,
                        roles: group.roles,
                        users: group.users,
                    };
                });

                const contactableUsers = [];

                for (const user of this.activeUsers) {
                    if (user.phones && (user.id === this.authUser.id || user.active)) {
                        const cellPhone = user.phones.find(phone => phone.cellphone);

                        contactableUsers.push({
                            cellphone: !empty(cellPhone),
                            id: user.id,
                            text: this.getFullName(user),
                        });
                    }
                }

                return guestGroups.concat(contactableUsers);
            },
        },

        watch: {
            selectedItemIds: {
                immediate: true,
                async handler(newValue, oldValue) {
                    const noChanges = !(newValue || []).some(item => !(oldValue || []).includes(item)) &&
                        !(oldValue || []).some(item => !(newValue || []).includes(item));

                    if (noChanges) {
                        return;
                    }

                    this.syncWithGroups(this.selectedItemIds);

                    await this.$nextTick();

                    if (this.$refs.alertUsers.search) {
                        this.$refs.alertUsers.search = '';
                        this.$refs.alertUsers.$refs.search.focus();
                    }
                },
            },
        },

        methods: {
            getActiveGroupIdsByUsers(userIds) {
                return this.account.guest_groups
                    .filter(group => !this.getGroupUserIds(group).some(groupUserId => !userIds.includes(groupUserId)))
                    .map(group => `G${group.id}`);
            },

            getActiveUsersByGuestGroups(users) {
                return this.activeOrNotifiedUsers.filter(user => users.includes(user.id));
            },

            getActiveUsersBySelectedPosts(posts) {
                return this.activeOrNotifiedUsers.filter(user => posts.includes(user.post_id));
            },

            getActiveUsersBySelectedRoles(roles) {
                return this.activeOrNotifiedUsers.filter(user => roles.includes(user.role_id));
            },

            getAddedGroupUserIds(itemIds) {
                const addedGroupId = itemIds.find(itemId => String(itemId).startsWith('G') && !this.selectedItemIds.includes(itemId));

                if (addedGroupId) {
                    const addedGroup = this.contactableUsers.find(item => item.id === addedGroupId);

                    if (addedGroup) {
                        return this.getGroupUserIds(addedGroup);
                    }
                }

                return [];
            },

            getGroupUserIds(group) {
                const groupUsersByPosts = this.getActiveUsersBySelectedPosts(group.posts);
                const groupUsersByRoles = this.getActiveUsersBySelectedRoles(group.roles);
                const groupUsersByUsers = this.getActiveUsersByGuestGroups(group.users);

                return uniqBy(groupUsersByPosts.concat(groupUsersByRoles).concat(groupUsersByUsers), 'id')
                    .map(user => user.id);
            },

            getRemovedGroupUserIds(itemIds) {
                const removedGroupId = this.selectedItemIds.find(itemId => String(itemId).startsWith('G') && !itemIds.includes(itemId));

                if (removedGroupId) {
                    const removedGroup = this.contactableUsers.find(item => item.id === removedGroupId);

                    if (removedGroup) {
                        return this.getGroupUserIds(removedGroup);
                    }
                }

                return [];
            },

            syncWithGroups(itemIds) {
                const syncedItemIds = new Set(itemIds);

                this.getAddedGroupUserIds(itemIds).forEach(syncedItemIds.add, syncedItemIds);
                this.getRemovedGroupUserIds(itemIds).forEach(syncedItemIds.delete, syncedItemIds);

                const activeGroupIds = this.getActiveGroupIdsByUsers([...syncedItemIds]);
                activeGroupIds.forEach(syncedItemIds.add, syncedItemIds);

                const inactiveGroupIds = [...syncedItemIds].filter(itemId => String(itemId).startsWith('G') && !activeGroupIds.includes(itemId));
                inactiveGroupIds.forEach(syncedItemIds.delete, syncedItemIds);

                this.$emit('update:selected-item-ids', [...syncedItemIds]);
            },
        },
    };
</script>
