<template>
    <table class="table-fitted | w-full table-fixed border-collapse">
        <slot name="header" />
        <slot name="content" />
        <slot name="footer" />
    </table>
</template>

<script>
    export default {};
</script>

<style scoped>
    table.table-fitted >>> td,
    table.table-fitted >>> th {
        @apply px-3 py-2;
    }
    table.table-fitted >>> td:first-child,
    table.table-fitted >>> th:first-child {
        @apply pl-6;
    }
    table.table-fitted >>> td:last-child,
    table.table-fitted >>> th:last-child {
        @apply pr-6;
    }
</style>
