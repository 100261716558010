import { render, staticRenderFns } from "./ExpandableElement.vue?vue&type=template&id=631ff644&scoped=true&"
import script from "./ExpandableElement.vue?vue&type=script&lang=js&"
export * from "./ExpandableElement.vue?vue&type=script&lang=js&"
import style0 from "./ExpandableElement.vue?vue&type=style&index=0&id=631ff644&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631ff644",
  null
  
)

export default component.exports