var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        relative flex flex-grow items-stretch rounded-sm shadow-none bg-white bg-opacity-0\n        | focus-within:bg-opacity-100\n    ",class:[_vm.positioningClassName, {
        'focus-within:z-50 focus-within:shadow focus:bg-opacity-100': !_vm.disabled,
        '-ml-2.5': _vm.animated,
        'text-gray-500': _vm.disabled,
    }],style:({
        height: (_vm.baseHeight + "px"),
    })},[_c('textarea-autosize',{ref:"textarea",staticClass:"\n            flex-1 px-2.5 py-1.5 overflow-y-hidden rounded-sm placeholder-gray-500 resize-none\n            transition duration-150\n            ring-1 ring-gray-200 ring-opacity-0\n            !bg-white border border-gray-300 border-opacity-0\n            focus-within:ring-blue-500 focus-within:ring-opacity-100 focus-within:border-blue-500 focus-within:border-opacity-100\n        ",class:{
            'hover:border-opacity-100 focus-within:border-opacity-100': !_vm.disabled,
        },attrs:{"autosize":_vm.autoExpand && _vm.focused,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"rows":_vm.rows,"value":_vm.value,"max-height":252},nativeOn:{"blur":function($event){return _vm.onBlur.apply(null, arguments)},"focus":function($event){return _vm.onFocus.apply(null, arguments)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }