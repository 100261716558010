<template>
    <figure class="relative flex-shrink-0 flex place-items-stretch w-18 h-18 p-1.5 rounded-md shadow-md bg-white">
        <div class="flex-1 flex justify-center items-center border border-gray-300 rounded-sm bg-gray-200">
            <icon
                class="text-gray-600 text-2xl | lg:text-3xl"
                :name="lead.typeIcon"
            />
            <icon
                class="absolute bottom-0 right-0 -mb-0.5 -mr-0.5 p-0.5 text-xl rounded-full bg-white shadow"
                :class="statusTextColor"
                :name="lead.statusIcon"
            />
        </div>
    </figure>
</template>

<script>
    import Lead from '@/entities/Lead';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            statusIcon() {
                return this.lead.statusIcon;
            },

            statusTextColor() {
                return {
                    valid: 'text-green-500',
                    lost: 'text-red-500',
                    duplicate: 'text-gray-600',
                    invalid: 'text-orange-500',
                }[this.lead.status || 'valid'];
            },

            typeIcon() {
                return this.lead.typeIcon;
            },
        },
    };
</script>
