<template>
    <aside
        class="flex-col justify-between shadow-md bg-gray-50 | hidden sm:flex | print:hidden"
        :class="isExpanded ? 'w-80' : 'w-30'"
    >
        <header class="flex items-center h-32 border-gray-200 bg-gray-50 | transition hover:bg-gray-200">
            <router-link
                :to="{
                    name: 'customer.update',
                    params: { id: customer.id },
                }"
                v-if="$feature.isEnabled('new-lead-page') && $feature.isEnabled('customer-page')"
            >
                <associated-leads-customer-header
                    :customer-name="customerName"
                    :customer-since-year="customerSinceYear"
                    :is-expanded="isExpanded"
                />
            </router-link>
            <associated-leads-customer-header
                :customer-name="customerName"
                :customer-since-year="customerSinceYear"
                :is-expanded="isExpanded"
                v-else
            />
        </header>

        <nav class="flex-1 flex flex-col py-3 border-t border-gray-200 bg-white overflow-auto" v-if="isLoaded">
            <associated-lead-item
                :active="associatedLead.id === activeLeadId"
                :is-expanded="isExpanded"
                :lead="associatedLead"
                :key="index"
                v-for="(associatedLead, index) in filteredAssociatedLeads"
            />

            <activix-tooltip
                :content="$t('filters.clearAllFilters')"
                placement="right"
                v-if="hasFiltersApplied"
            >
                <button
                    class="appearance-none py-6 uppercase text-sm text-gray-550 font-semibold break-words | transition hover:bg-gray-200"
                    @click="$refs.filters.resetFilters"
                    v-text="$t('filters.showAll')"
                />
            </activix-tooltip>
        </nav>

        <div class="sticky inset-x-0 bottom-0 z-10 flex flex-col divide-y divide-gray-200 border-t border-gray-200">
            <slot
                name="actions"
                :is-expanded="isExpanded"
                :related-lead-id="firstAssociatedLeadId"
                v-if="isLoaded"
            />

            <associated-leads-filters
                :account="customer.account"
                :active-lead-id="activeLeadId"
                :leads="associatedLeads"
                :with-label="isExpanded"
                ref="filters"
                @associated-leads-filtered="onAssociatedLeadsFiltered"
                v-if="isLoaded"
            />

            <activix-tooltip placement="right" :content="isExpanded ? '' : $t('clientCard.associatedLeads.expand')">
                <button
                    class="flex items-center h-18 p-6 bg-white | hover:bg-gray-200"
                    :class="isExpanded ? 'justify-start' : 'justify-center'"
                    @click="isExpanded = !isExpanded"
                >
                    <span class="flex-shrink-0 flex place-items-stretch w-18 h-18 p-1.5">
                        <span class="relative flex-1 flex justify-center items-center">
                            <icon class="text-lg" name="regular/arrow-button-right-1" v-if="!isExpanded" />
                            <icon class="text-lg" name="regular/arrow-button-left-1" v-if="isExpanded" />
                        </span>
                    </span>

                    <span
                        class="ml-1.5"
                        v-text="$t('clientCard.associatedLeads.collapse')"
                        v-if="isExpanded"
                    />
                </button>
            </activix-tooltip>
        </div>
    </aside>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useAssociatedLeadsStore } from '@/store/modules/associatedLeads/store';

    import AssociatedLeadItem from '@/components/container/lead/AssociatedLeadItem';
    import AssociatedLeadsCustomerHeader from '@/components/container/lead/AssociatedLeadsCustomerHeader';
    import AssociatedLeadsFilters from '@/components/container/lead/AssociatedLeadsFilters';

    import ActivixDate from '@/value-objects/ActivixDate';
    import Customer from '@/entities/Customer';

    export default {
        components: {
            AssociatedLeadItem,
            AssociatedLeadsCustomerHeader,
            AssociatedLeadsFilters,
        },

        props: {
            activeLeadId: {
                type: Number,
                default: null,
            },
            customer: {
                type: Customer,
                required: true,
            },
        },

        data: () => ({
            filteredAssociatedLeads: [],
            hasFiltersApplied: false,
            isExpanded: false,
        }),

        computed: {
            ...mapState(useAssociatedLeadsStore, ['associatedLeads']),

            customerName() {
                let customerName = this.customer.fullName;

                if (!customerName) {
                    customerName = this.associatedLeads.find(lead => lead.fullName)?.fullName;
                }

                return customerName || '';
            },

            customerSinceYear() {
                const oldestLead = [...this.associatedLeads]
                    .sort((a, b) => a.created_at.localeCompare(b.created_at))[0];

                const oldestLeadCreatedAt = new ActivixDate(oldestLead?.created_at);

                return oldestLeadCreatedAt.year;
            },

            firstAssociatedLeadId() {
                return this.associatedLeads[0]?.id;
            },

            isLoaded() {
                return this.associatedLeads.length;
            },
        },

        watch: {
            'customer.id': {
                immediate: true,
                handler() {
                    if (!this.customer.id) {
                        return;
                    }

                    this.fetchAssociatedLeads(this.customer.id);
                },
            },

            isExpanded() {
                this.$ls.set('associatedLeads.expanded', this.isExpanded);
            },
        },

        methods: {
            ...mapActions(useAssociatedLeadsStore, ['fetchAssociatedLeads']),

            onAssociatedLeadsFiltered({ filteredAssociatedLeads, hasFiltersApplied }) {
                this.filteredAssociatedLeads = filteredAssociatedLeads;
                this.hasFiltersApplied = hasFiltersApplied;
            },
        },

        created() {
            this.isExpanded = this.$ls.get('associatedLeads.expanded', false);
        },
    };
</script>
