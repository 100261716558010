<template>
    <activix-tooltip
        placement="right"
        :content="tooltip"
    >
        <button
            class="flex items-center h-18 p-6 bg-white | hover:bg-gray-200"
            :class="withLabel ? 'justify-start' : 'justify-center'"
            v-popover:filters
        >
            <span class="flex-shrink-0 flex place-items-stretch w-18 h-18 p-1.5">
                <span class="relative flex-1 flex justify-center items-center">
                    <icon class="text-xl" name="regular/filter-1" />
                    <span
                        class="badge | absolute ml-8"
                        :class="['badge-success']"
                        v-if="hasFiltersApplied"
                    />
                </span>
            </span>

            <span class="ml-1.5" v-if="withLabel">{{ label }}</span>

            <el-popover
                placement="right"
                trigger="click"
                ref="filters"
                @click.stop
            >
                <filters
                    :filters="filters"
                    @apply="applyFilters"
                    @reset="resetFilters"
                />
            </el-popover>
        </button>
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import Filters from '@/components/container/Filters';

    import Account from '@/entities/Account';
    import Division from '@/entities/Division';
    import LeadStatus from '@/entities/LeadStatus';
    import LeadType from '@/entities/LeadType';

    const initialFilters = {
        assignedToMe: null,
        division: [],
        status: [],
        type: [],
    };

    export default {
        components: { Filters },

        props: {
            account: {
                type: Account,
                required: true,
            },
            activeLeadId: {
                type: Number,
                default: null,
            },
            leads: {
                type: Array,
                required: true,
            },
            withLabel: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            filterValues: initialFilters,
        }),

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            associatedLeads() {
                if (!this.leads.length) {
                    return [];
                }

                const leads = this.leads
                    .filter(lead => {
                        // Filter import and division
                        return (
                            (empty(lead.import) || (lead.import.finished_at && lead.import.validated)) &&
                            this.authUser.hasAccessToEditAccount(lead.account_id) &&
                            (lead.division_id !== Division.SERVICE ||
                                this.authUser.isAdmin() ||
                                this.authUser.module_access.service === true)
                        );
                    });

                if (leads.length) {
                    return leads;
                }

                if (this.activeLeadId) {
                    return this.leads.find(lead => lead.id === this.activeLeadId) || this.leads[0];
                }

                return this.leads[0];
            },

            filteredAssociatedLeads() {
                return this.associatedLeads.filter(this.leadMatchesFilters);
            },

            filters() {
                const statusOptions = LeadStatus.selectOptions('value', 'text');
                statusOptions.unshift({ value: 'active', text: this.$t('result.active') });

                return {
                    status: {
                        label: this.$t('clientCard.status'),
                        options: statusOptions,
                        multiple: true,
                        type: 'select',
                        value: this.filterValues.status,
                    },
                    type: {
                        label: this.$t('clientCard.type'),
                        options: LeadType.selectOptions(this.account, this.authUser, [], 'value', 'text')
                            .filter(option => !option.invalid),
                        multiple: true,
                        type: 'select',
                        value: this.filterValues.type,
                    },
                    division: {
                        label: this.$t('clientCard.division'),
                        options: Division.selectOptions(this.account, 'value', 'text')
                            .filter(option => !option.invalid),
                        multiple: true,
                        type: 'select',
                        value: this.filterValues.division,
                    },
                    assignedToMe: {
                        label: this.$t('clientCard.associatedLeads.assignedToMe'),
                        type: 'bool',
                        value: this.filterValues.assignedToMe,
                    },
                };
            },

            hasFiltersApplied() {
                return Object.entries(this.filterValues).some(([name, value]) => {
                    const initialFilter = initialFilters[name];

                    if (initialFilter instanceof Array) {
                        const selectedValues = value || [];

                        return selectedValues.length !== initialFilter.length ||
                            selectedValues.some(selectedValue => !initialFilter.includes(selectedValue));
                    }

                    return value !== initialFilter;
                });
            },

            label() {
                return this.$t('clientCard.associatedLeads.filterLeads');
            },

            tooltip() {
                if (this.withLabel) {
                    return '';
                }

                return this.label;
            },
        },

        watch: {
            filteredAssociatedLeads() {
                this.$emit('associated-leads-filtered', {
                    filteredAssociatedLeads: this.filteredAssociatedLeads,
                    hasFiltersApplied: this.hasFiltersApplied,
                });
            },
        },

        methods: {
            applyFilters(selectedFilters) {
                this.close();

                this.updateFilters({
                    ...this.filterValues,
                    ...selectedFilters,
                });
            },

            close() {
                this.$refs.filters.doClose();
            },

            initFilters() {
                const initialFiltersFromStorage = this.$ls.get('associatedLeads.filters', initialFilters);

                this.updateFilters(initialFiltersFromStorage);
            },

            leadMatchesAssignedToMe(lead) {
                if (this.filterValues.assignedToMe === null) {
                    return true;
                }

                const isAssignedTo = lead.isAssignedTo(this.authUser.id, true);

                return this.filterValues.assignedToMe ? isAssignedTo : !isAssignedTo;
            },

            leadMatchesDivision(lead) {
                if (!this.filterValues.division.length) {
                    return true;
                }

                return this.filterValues.division.includes(lead.division_id);
            },

            leadMatchesFilters(lead) {
                return this.leadMatchesAssignedToMe(lead) &&
                    this.leadMatchesDivision(lead) &&
                    this.leadMatchesStatus(lead) &&
                    this.leadMatchesType(lead);
            },

            leadMatchesStatus(lead) {
                if (!this.filterValues.status.length) {
                    return true;
                }

                return this.filterValues.status.some(statusFilter => {
                    if (statusFilter === 'active') {
                        return !lead.status;
                    }

                    return lead.status === statusFilter;
                });
            },

            leadMatchesType(lead) {
                if (!this.filterValues.type.length) {
                    return true;
                }

                return this.filterValues.type.includes(lead.lead_type_id);
            },

            resetFilters() {
                this.close();

                this.updateFilters(initialFilters);
            },

            updateFilters(filters) {
                this.$set(this, 'filterValues', filters);
                this.$ls.set('associatedLeads.filters', filters);
            },
        },

        created() {
            this.initFilters();
        },
    };
</script>
