<template>
    <div class="relative grid grid-cols-1 | md:grid-cols-3">
        <vehicle-list-item-header-menu
            :vehicle="vehicle"
            @delete-vehicle="$emit('delete-vehicle', $event)"
            v-if="!readonly"
        />

        <picture
            class="flex justify-center items-center p-3 overflow-hidden | lg:p-6"
            :class="{'opacity-50': !vehicle.make || !vehicle.model || !vehicle.year}"
        >
            <img
                class="flex-grow max-h-48 object-contain | mg:max-h-32"
                src="@/assets/img/powersport.png"
                alt="Powersport vehicle"
                v-if="category === 'powersport'"
            />
            <img
                alt="Car wireframe"
                class="flex-grow max-h-48 object-contain | mg:max-h-32"
                :src="vehicleImage"
                v-else
            />
        </picture>

        <div class="col-span-full grid grid-cols-2 place-content-center items-start gap-6 p-6 | md:col-span-2 md:items-center">
            <div class="flex flex-col">
                <div class="space-x-1">
                    <span
                        :class="{'italic text-gray-500' : !vehicle.year}"
                        v-text="vehicle.year || $t('clientCard.vehicles.year')"
                    />
                    <span
                        :class="{'italic text-gray-500': !vehicle.make}"
                        v-text="vehicle.make || $t('clientCard.vehicles.make')"
                    />
                </div>
                <div
                    class="text-xl"
                    :class="vehicle.model ? 'text-gray-800': 'italic text-gray-500'"
                    v-text="vehicle.model || $t('clientCard.vehicles.model')"
                />
                <div
                    :class="vehicle.trim ? 'text-sm text-gray-700': 'italic text-gray-500'"
                    v-text="vehicle.trim || $t('clientCard.vehicles.noTrim')"
                />
            </div>

            <div class="flex flex-col items-start gap-3">
                <div class="flex items-stretch" v-if="status">
                    <div
                        class="z-10 min-w-24 px-3 py-1 rounded-full text-center text-sm text-white whitespace-nowrap"
                        :class="statusColor"
                        v-text="statusText"
                    />

                    <activix-tooltip :content="$t('clientCard.vehicles.verifiedTooltip')" v-if="saleHasBeenVerified">
                        <div class="-ml-3 pl-5 pr-3 rounded-r-full" :class="verifiedSaleStatusColor">
                            <icon class="text-lg text-white" name="regular/check-double-1" />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="flex items-center space-x-1.5">
                    <div class="flex items-center text-sm" :class="vehicle.stock_state === 'order' ? 'rounded-l-sm' : 'rounded-sm'">
                        <span class="mr-1.5 w-1.5 h-1.5 rounded-full" :class="stockStateColor" /> {{ stockStateText }}
                    </div>

                    <samp
                        class="tracking-wide text-sm tabular-nums text-gray-600"
                        v-text="`#${vehicle.stock}`"
                        v-if="vehicle.stock"
                    />
                </div>

                <div
                    class="flex items-center text-sm"
                    :class="{ 'italic text-gray-500': !vehicle.modality }"
                    v-text="`${modalityDetails}`"
                    v-if="vehicle.isExchange"
                />

                <vehicle-equity :vehicle="vehicle" v-if="showEquity" />

                <span
                    class="text-sm"
                    v-text="mileageText"
                    v-if="vehicle.mileage"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { toLocaleNumber } from '@/utils/numbers';

    import VehicleEquity from '@/components/container/lead/vehicles/VehicleEquity';
    import VehicleListItemHeaderMenu from '@/components/container/lead/vehicles/VehicleListItemHeaderMenu.vue';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LeadVehicleModality from '@/entities/LeadVehicleModality';

    export default {
        components: { VehicleEquity, VehicleListItemHeaderMenu },

        props: {
            account: {
                type: Account,
                required: true,
            },
            category: {
                type: String,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            lead() {
                return this.vehicle.lead;
            },

            isContractualModality() {
                return [LeadVehicleModality.FINANCING, LeadVehicleModality.LEASING].includes(this.vehicle.modality);
            },

            isPolestarAccount() {
                return this.account.isPolestar();
            },

            isPolestarDeposited() {
                return this.isPolestarAccount && this.vehicle.isWanted && this.vehicle.isPolestarDeposited;
            },

            mileageText() {
                return `${toLocaleNumber(this.vehicle.mileage)} km`;
            },

            modalityDetails() {
                if (!this.isContractualModality || !this.vehicle.term || this.vehicle.rate === null) {
                    return this.modalityText;
                }

                const term = `${this.vehicle.term} ${this.$tc('delayUnits.months', this.vehicle.term)}`;
                const rate = `${this.vehicle.rate} %`;

                return `${this.modalityText} ${term} @ ${rate}`;
            },

            modalityText() {
                if (!this.vehicle.modality) {
                    return this.$t('clientCard.boxes.vehicles.noModalityInfo');
                }

                const key = {
                    cash: 'leadVehicleModalities.cash',
                    financing: 'leadVehicleModalities.financing',
                    leasing: 'leadVehicleModalities.leasing',
                }[this.vehicle.modality];

                return this.$t(key);
            },

            saleHasBeenVerified() {
                if (!this.vehicle.isWanted) {
                    return false;
                }

                if (!this.lead.isSoldOrRefinanced()) {
                    return false;
                }

                return !!this.vehicle.verified_by_id;
            },

            showEquity() {
                return (
                    this.vehicle.isExchange &&
                    (this.fieldIsVisible('balance') ||
                        this.fieldIsVisible('residual') ||
                        this.fieldIsVisible('end_contract_date'))
                );
            },

            status() {
                if (this.vehicle.isExchange) {
                    return this.getExchangeStatus();
                }

                return this.getWantedStatus();
            },

            statusColor() {
                if (!this.status) {
                    return '';
                }

                if (this.isPolestarAccount) {
                    if (this.status === 'polestarTestDrive') {
                        return 'bg-blue-500';
                    }

                    return 'bg-green-500';
                }

                return {
                    delivered: 'bg-green-500',
                    refinanced: 'bg-blue-500',
                    renewed: 'bg-blue-500',
                    sold: 'bg-blue-500',
                }[this.status];
            },

            statusText() {
                if (!this.status) {
                    return '';
                }

                let mainStatus = '';

                if (this.status === 'polestarTestDrive') {
                    mainStatus = this.getPolestarTestDriveLabel();
                } else {
                    const key = {
                        delivered: 'clientCard.delivered',
                        refinanced: 'clientCard.refinanced',
                        renewed: 'clientCard.renewal',
                        sold: 'clientCard.sold',
                    }[this.status];

                    mainStatus = key ? this.$t(key) : '';
                }

                const statusArray = [mainStatus];

                if (this.isPolestarDeposited) {
                    statusArray.push(this.$t('clientCard.deposit'));
                }

                return statusArray.filter(s => s).join(' - ');
            },

            stockStateColor() {
                if (!this.vehicle.stock_state) {
                    return 'bg-gray-300';
                }

                return {
                    locate: 'bg-orange-500',
                    notAvailable: 'bg-red-500',
                    order: 'bg-blue-500',
                    stock: 'bg-green-500',
                }[this.vehicle.stock_state];
            },

            stockStateText() {
                if (!this.vehicle.stock_state) {
                    return this.$t('clientCard.boxes.vehicles.stockToBeDefined');
                }

                const key = {
                    locate: 'leadVehicleStockStates.locate',
                    notAvailable: 'leadVehicleStockStates.notAvailable',
                    order: 'leadVehicleStockStates.order',
                    stock: 'leadVehicleStockStates.stock',
                }[this.vehicle.stock_state];

                return this.$t(key);
            },

            vehicleImage() {
                // To be replaced with external vehicle image API.
                const externalVehicleImage = null;

                // eslint-disable-next-line global-require
                return externalVehicleImage || require('@/assets/img/car-wireframe.png');
            },

            vehicleModel() {
                const model = this.vehicle.model || this.$t('clientCard.vehicles.model');

                return `${model} ${this.vehicle.trim}`.trim();
            },

            verifiedSaleStatusColor() {
                if (this.isPolestarAccount || this.status === 'delivered') {
                    return 'bg-green-600';
                }

                return 'bg-blue-600';
            },
        },

        methods: {
            fieldIsVisible(field) {
                return this.account.hasVehicleField(field, this.vehicle.type, false);
            },

            getExchangeStatus() {
                return null;
            },

            getPolestarTestDriveLabel() {
                if (this.vehicle.isPolestarTestDriveAYL) {
                    return this.$t('clientCard.roadTestAYL');
                }

                if (this.vehicle.isPolestarTestDriveTDM) {
                    return this.$t('clientCard.roadTestTDM');
                }

                return this.$tc('clientCard.roadTest');
            },

            getWantedStatus() {
                if (!this.vehicle.sold) {
                    if (this.isPolestarAccount && this.vehicle.isPolestarTestDrive) {
                        return 'polestarTestDrive';
                    }

                    return null;
                }

                if (this.lead.delivered_date) {
                    return 'delivered';
                }

                if (
                    this.lead.isOfType(5) &&
                    !this.vehicle.sale_date &&
                    this.lead.refinanced_date
                ) {
                    return 'refinanced';
                }

                if (
                    this.lead.isOfType(5) &&
                    (this.lead.sale_date || this.lead.sale_by_phone) &&
                    this.lead.refinanced_date
                ) {
                    return 'renewed';
                }

                if (
                    this.lead.isNotOfType(5) &&
                    (this.lead.sale_date || this.lead.sale_by_phone)
                ) {
                    return 'sold';
                }

                return null;
            },
        },
    };
</script>
