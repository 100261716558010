<template>
    <div
        class="grid grid-cols-3 gap-3 -mx-6 p-6 border-t border-b border-gray-200 bg-gray-100"
        v-if="isExportable"
    >
        <activix-tooltip :content="exportedTooltip" v-if="exportableSuppliers.length > 0">
            <div class="flex flex-col items-center text-center" :class="{ 'col-span-3': !exportedSupplier }">
                <activix-tooltip :content="disabledTooltip" v-if="!readonly && !statusIsWaiting && !statusIsReceived">
                    <div><!-- Div for tooltip glitch on disabled child -->
                        <activix-button
                            class="tracking-wider uppercase text-sm"
                            :disabled="exportDisabled"
                            size="small"
                            @click="exportVehicle"
                        >
                            {{ label }}
                        </activix-button>
                    </div>
                </activix-tooltip>

                <label
                    class="mb-0 text-gray-700 tracking-wider uppercase text-sm"
                    :class="{
                        'cursor-not-allowed': exportDisabled,
                        'cursor-pointer': !exportedSupplier && !exportDisabled,
                    }"
                    v-else
                >
                    {{ label }}
                </label>

                <img
                    class="max-h-8 max-w-32 m-1.5 mt-3"
                    :class="{ 'opacity-50': exportDisabled }"
                    :alt="$t('clientCard.provider')"
                    :src="exportSupplierImg"
                />
            </div>
        </activix-tooltip>

        <template v-if="exportedSupplier">
            <div class="text-center ">
                <label class="mb-0 text-gray-700 tracking-wider uppercase text-sm">
                    {{ $t('clientCard.vehicles.estimatedBy') }}
                </label>

                <samp class="block font-sans tabular-nums" v-text="estimatedBy" />
                <date-distance :date="exportedSupplierData.imported_on" />
            </div>

            <div class="text-center" v-if="calculatorValueVisible">
                <label class="mb-0 text-gray-700 tracking-wider uppercase text-sm">
                    {{ $t('clientCard.vehicles.estimated') }}
                </label>

                <samp class="block font-sans tabular-nums" v-text="`${$t('clientCard.exportWaiting')}...`" v-if="statusIsWaiting" />
                <samp class="block font-sans tabular-nums" v-text="toCurrency(vehicle.calculator_value)" v-else />

            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import DateDistance from '@/components/container/date/DateDistance';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import SuppliableStatus from '@/entities/SuppliableStatus.js';
    import Supplier from '@/entities/Supplier.js';

    export default {
        components: {
            DateDistance,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            calculatorValueVisible() {
                return this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isCommercialDirector() ||
                    this.authUser.calculator_value_visible;
            },

            disabledTooltip() {
                if (this.statusIsWaiting || this.statusIsReceived) {
                    return '';
                }

                if (!this.hasMandatoryExportFields) {
                    if (this.supplierIsAutopropulsion) {
                        return this.$t('suppliers.tooltip.mandatoryWithOdometer');
                    }

                    if (this.supplierIsQuickTrade) {
                        return this.$t('suppliers.tooltip.mandatoryOnlyVin');
                    }

                    return this.$t('suppliers.tooltip.mandatory');
                }

                if (!this.hasValidValueProfit) {
                    return this.$t('suppliers.tooltip.valuesCannotBeNegatives');
                }

                if (!this.hasValidVin) {
                    return this.$t('suppliers.tooltip.validVin');
                }

                if (this.isExported || this.isImported || this.isExporting) {
                    return this.$t('suppliers.tooltip.doublesubmit');
                }

                return '';
            },

            estimatedBy() {
                if (this.vehicle.calculator_value) {
                    return this.vehicle.calculator_appraiser;
                }

                if (this.exportedSupplierData.status === 'waiting') {
                    return `${this.$t('clientCard.exportWaiting')}...`;
                }

                return this.$t('clientCard.vehicles.noEstimate');
            },

            exportDisabled() {
                return this.readonly ||
                    !this.hasMandatoryExportFields ||
                    !this.hasValidExportFields ||
                    this.isExporting;
            },

            exportSupplier() {
                const exportSupplier = this.exportableSuppliers.find(supplier => supplier.id === this.exportedSupplier?.id);

                return exportSupplier || this.exportableSuppliers[0];
            },

            exportSupplierImg() {
                if (this.exportSupplier.id === Supplier.VAUTO) {
                    // eslint-disable-next-line global-require
                    return require('@/assets/img/vauto.png');
                }

                if (this.exportSupplier.id === Supplier.AUTOPROPULSION) {
                    // eslint-disable-next-line global-require
                    return require('@/assets/img/autopropulsion.png');
                }

                if (this.exportSupplier.id === Supplier.QUICKTRADE) {
                    // eslint-disable-next-line global-require
                    return require('@/assets/img/torque-management.png');
                }

                return null;
            },

            exportableSuppliers() {
                return this.account.suppliers.filter(supplier => {
                    return LeadVehicle.exportableSuppliers.includes(supplier.id) && supplier.outbound;
                });
            },

            exportedSupplier() {
                const exportableSupplierIds = this.exportableSuppliers.map(supplier => supplier.id);

                return this.vehicle.suppliers.find(vehicleSupplier => {
                    const vehicleSupplierId = vehicleSupplier.supplier_id || vehicleSupplier.id;

                    return exportableSupplierIds.includes(vehicleSupplierId);
                });
            },

            exportedSupplierData() {
                return this.exportedSupplier?.pivot || this.exportedSupplier?.state || null;
            },

            exportedTooltip() {
                if (this.statusIsReceived) {
                    return this.$t('suppliers.tooltip.received', [
                        this.vehicle.calculator_appraiser,
                        locale_dt(this.supplierResponse.imported_on).humanShort(),
                    ]);
                }

                if (this.statusIsWaiting) {
                    return this.$t('suppliers.label.waiting', [
                        locale_dt(this.supplierResponse.exported_on).humanShort(),
                    ]);
                }

                return '';
            },

            hasMandatoryExportFields() {
                if (this.supplierIsQuickTrade && this.vehicle.vin) {
                    return true;
                }

                if (!this.vehicle.vin || !this.vehicle.make || !this.vehicle.model || !this.vehicle.year) {
                    return false;
                }

                return !(this.supplierIsAutopropulsion && !this.vehicle.mileage);
            },

            hasValidExportFields() {
                return !(!this.hasValidValueProfit || !this.hasValidVin);
            },

            hasValidValueProfit() {
                return !(this.supplierIsVAuto && (this.vehicle.value < 0 || this.vehicle.profit < 0));
            },

            hasValidVin() {
                return this.vehicle.vin.length === 17;
            },

            isExportable() {
                return !!this.exportableSuppliers.length;
            },

            isExporting() {
                return !!this.$wait.is(`vehicles[${this.vehicle.id}].exporting`);
            },

            isExported() {
                return this.exportedSupplierData?.exported;
            },

            isImported() {
                return this.exportedSupplierData?.imported;
            },

            label() {
                if (this.statusIsReceived) {
                    return this.$t('clientCard.exportCompleted');
                }

                if (this.statusIsWaiting) {
                    return this.$t('clientCard.exportWaiting');
                }

                return this.readonly ? '' : this.$t('general.submit');
            },

            statusIsReceived() {
                return this.supplierStatus === SuppliableStatus.RECEIVED;
            },

            statusIsWaiting() {
                return this.supplierStatus === SuppliableStatus.WAITING;
            },

            supplierIsAutopropulsion() {
                return this.exportSupplier?.id === Supplier.AUTOPROPULSION;
            },

            supplierIsQuickTrade() {
                return this.exportSupplier?.id === Supplier.QUICKTRADE;
            },

            supplierIsVAuto() {
                return this.exportSupplier?.id === Supplier.VAUTO;
            },

            supplierResponse() {
                if (this.exportedSupplierData) {
                    return this.exportedSupplierData;
                }

                return {
                    imported_on: now(),
                };
            },

            supplierStatus() {
                if (!this.exportedSupplier) {
                    return SuppliableStatus.UNSUBMITTED;
                }

                if (this.exportedSupplier.status) {
                    return this.exportedSupplier.status;
                }

                if (this.exportedSupplier.state) {
                    return this.exportedSupplier.state.status || SuppliableStatus.UNSUBMITTED;
                }

                return SuppliableStatus.UNSUBMITTED;
            },
        },

        methods: {
            async exportVehicle() {
                if (this.exportDisabled || this.isExported) {
                    return;
                }

                this.$emit('export-vehicle', {
                    supplierId: this.exportSupplier.id,
                    vehicle: this.vehicle,
                });
            },
        },
    };
</script>
