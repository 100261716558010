<template>
    <button-icon
        class="border-l border-gray-200 divide-x divide-gray-200 py-3 w-14 last:rounded-tr-lg | md:w-18 xl:w-14 2xl:w-18"
        :disabled="disabled"
        :icon-name="iconName"
        :icon-class-name="iconClassName"
        :icon-size-class-name="iconSizeClassName"
        :indicator="indicator"
        :indicator-class-name="indicatorClassName"
        :indicator-type="indicatorType"
        :tooltip="tooltip"
        @click="$emit('click')"
    >
        <template #popoverContent="{ closePopover }" v-if="$scopedSlots.popoverContent">
            <slot name="popoverContent" :close-popover="closePopover" />
        </template>
    </button-icon>
</template>

<script>
    import ButtonIcon, { indicatorTypes } from '@/components/presentational/ButtonIcon';

    export default {
        components: { ButtonIcon },

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            iconClassName: {
                type: [String, Array, Object],
            },
            iconName: {
                type: String,
            },
            indicator: {
                type: Boolean,
                default: false,
            },
            indicatorClassName: {
                type: [String, Array, Object],
                default: '',
            },
            iconSizeClassName: {
                type: [String, Array, Object],
                default: 'text-xl',
            },
            indicatorType: {
                type: String,
                default: 'info',
                validator: value => indicatorTypes.includes(value),
            },
            tooltip: {
                type: String,
                default: '',
            },
        },
    };
</script>
