import Enum from './Enum.js';

export default class SuppliableStatus extends Enum {
    static get entries() {
        return {
            unsubmitted: 'unsubmitted',
            waiting: 'waiting',
            received: 'received',
        };
    }

    static get UNSUBMITTED() {
        return this.entries.unsubmitted;
    }

    static get WAITING() {
        return this.entries.waiting;
    }

    static get RECEIVED() {
        return this.entries.received;
    }
}
