import Enum from './Enum.js';

export default class NoteAction extends Enum {
    static get entries() {
        return {
            reply: 'reply',
            edit: 'edit',
            create: 'create',
        };
    }

    static get REPLY() {
        return this.entries.reply;
    }

    static get EDIT() {
        return this.entries.edit;
    }

    static get CREATE() {
        return this.entries.create;
    }
}
