<template>
    <div class="flex items-center text-sm" v-if="hasEquity">
        <span class="mr-1.5 w-1.5 h-1.5 rounded-full" :class="statusColor" />

        <samp
            class="flex items-center font-sans text-sm rounded-r-sm"
            v-text="statusLabel"
        />

        <samp
            class="ml-2 tracking-wide text-sm tabular-nums text-gray-700"
            v-text="formattedEquity"
        />
    </div>
</template>

<script>
    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        props: {
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            equity() {
                return this.vehicle.calculateEquity();
            },

            formattedEquity() {
                return `(${this.toMoney(this.equity)})`;
            },

            hasEquity() {
                return this.equity || this.equity === 0;
            },

            statusColor() {
                if (this.equity >= 0) {
                    return 'bg-green-500';
                }

                return 'bg-red-500';
            },

            statusLabel() {
                if (this.equity >= 0) {
                    return this.$t('clientCard.boxes.vehicles.positiveEquity');
                }

                return this.$t('clientCard.boxes.vehicles.negativeEquity');
            },
        },
    };
</script>
