<template>
    <header class="flex justify-between items-stretch h-14 pl-6 border-b border-gray-200 | md:h-18 xl:h-14 2xl:h-18">
        <h3 class="flex items-center m-0 whitespace-nowrap text-lg font-semibold text-grey-650 | md:text-xl xl:text-lg 2xl:text-xl">
            <slot />
        </h3>

        <div class="flex">
            <slot name="right" />
        </div>
    </header>
</template>

<script>
    export default {};
</script>
