<template>
    <div class="relative truncate">
        <div class="flex items-center">
            <span class="overflow-ellipsis font-semibold text-gray-800" v-text="customerNameText" />
        </div>

        <div class="flex items-center">
            <span class="text-grey-700 |transition duration-200" v-text="customerSinceYearText" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            customerName: {
                type: String,
                required: true,
            },
            customerSinceYear: {
                validator: prop => typeof prop === 'number' || prop === null,
                required: true,
            },
        },

        computed: {
            customerNameText() {
                return this.customerName || this.$t('clientCard.unknown');
            },

            customerSinceYearText() {
                if (!this.customerSinceYear) {
                    return '';
                }

                return this.$t('clientCard.associatedLeads.customerSince', [this.customerSinceYear]);
            },
        },
    };
</script>
