<template>
    <div class="flex-grow">
        <invisible-input
            :value="readonlyValue"
            v-if="readonly"
        />

        <template v-else>
            <activix-tooltip :content="inputTooltip">
                <invisible-multiselect
                    :disabled="isDisabled"
                    :nullable="true"
                    :options="options"
                    :value="vehicle.make_warranty_id"
                    @input="$emit('update-vehicle', { make_warranty_id: $event })"
                />
            </activix-tooltip>

            <activix-confirm-modal
                :opened.sync="warrantyChangeModalOpened"
                :title="$t('general.areYouSure')"
                :content="$t('modal.warrantyChangeAlert')"
                name="warrantyChange"
                @approve="updateWarrantyEndDate"
            />
        </template>
    </div>
</template>

<script>
    import { mapActions, mapState, mapWritableState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';

    import { addSpaceBeetweenThreeNumber } from '@/utils/numbers.js';

    import InvisibleInput from '@/components/container/input/InvisibleInput';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import Account from '@/entities/Account';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            InvisibleInput,
            InvisibleMultiselect,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data: () => ({
            warrantyChangeModalOpened: false,
        }),

        computed: {
            ...mapState(useClientCardStore, ['vehicleMakes']),
            ...mapWritableState(useClientCardStore, ['vehicleMakeWarranties']),

            inputTooltip() {
                if (this.disabled) {
                    return '';
                }

                if (!this.options.length) {
                    return this.$t('accounts.edit.warrantyTypeDisableToolTip');
                }

                return '';
            },

            isDisabled() {
                return this.disabled || !this.options.length;
            },

            makeWarranties() {
                return this.vehicleMakeWarranties[this.makeWarrantyKey] || [];
            },

            makeWarranty() {
                return this.makeWarranties.find(makeWarranty => makeWarranty.id === this.vehicle.make_warranty_id);
            },

            makeWarrantyKey() {
                return `${this.vehicleMake.id}-${this.vehicle.year}`;
            },

            options() {
                return this.makeWarranties
                    .map(makeWarranty => {
                        return {
                            value: makeWarranty.id,
                            text: `${makeWarranty.mileage_max === 0 ? this.$t('delays.infinite') : addSpaceBeetweenThreeNumber(makeWarranty.mileage_max.toString())} KM, ${makeWarranty.coverage_months} ${this.$tc('delays.month', makeWarranty.coverage_months)}, ${this.$t(`clientCard.vehicles.${makeWarranty.type}Warranty`)}`,
                        };
                    });
            },

            readonlyValue() {
                if (!this.readonly) {
                    return '';
                }

                return this.options.find(option => option.value === this.vehicle.make_warranty_id)?.text;
            },

            vehicleMake() {
                return this.vehicleMakes.find(make => make.name === this.vehicle.make) || {};
            },
        },

        watch: {
            'vehicle.in_service_date'(newValue, oldValue) {
                if (this.readonly) {
                    return;
                }

                if (newValue && oldValue && new ActivixDate(newValue).isSame(new ActivixDate(oldValue))) {
                    return;
                }

                if (newValue && this.vehicle.end_warranty_date && this.vehicle.make_warranty_id) {
                    this.warrantyChangeModalOpened = true;
                }
            },

            vehicleMake: {
                immediate: true,
                handler() {
                    if (this.account.power_sport) {
                        return;
                    }

                    this.triggerFetchVehicleMakeWarranties();
                },
            },

            'vehicle.make_warranty_id'(newValue, oldValue) {
                if (this.readonly) {
                    return;
                }

                if (newValue === oldValue) {
                    return;
                }

                if (newValue && this.vehicle.end_warranty_date && this.vehicle.in_service_date) {
                    this.warrantyChangeModalOpened = true;
                }
            },

            'vehicle.year'() {
                this.triggerFetchVehicleMakeWarranties();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'fetchVehicleMakeWarranty',
            ]),

            syncMakeWarranty() {
                if (this.readonly || this.makeWarranty || !this.vehicle.make_warranty_id) {
                    return;
                }

                this.$emit('update-vehicle', { make_warranty_id: null });
            },

            async triggerFetchVehicleMakeWarranties() {
                if (!this.vehicle.make || !this.vehicle.year || isNaN(this.vehicle.year)) {
                    return;
                }

                if (this.makeWarranties.length) {
                    return;
                }

                const vehicleWarranties = await this.fetchVehicleMakeWarranty(this.vehicle.make, this.vehicle.year) || [];

                this.$set(this.vehicleMakeWarranties, this.makeWarrantyKey, vehicleWarranties);

                this.syncMakeWarranty();
            },

            updateWarrantyEndDate() {
                if (!this.makeWarranty) {
                    return;
                }

                if (this.makeWarranty.type === 'base') {
                    this.$emit('update-vehicle', {
                        end_warranty_date: new ActivixDate(this.vehicle.in_service_date).addMonths(this.makeWarranty.coverage_months).toString(),
                        end_warranty_mileage: this.makeWarranty.mileage_max,
                        end_extended_warranty_date: null,
                        end_extended_warranty_mileage: null,
                    });
                } else if (this.makeWarranty.type === 'extended') {
                    const makeWarrantyBase = this.makeWarranties.find(makeWarranty => makeWarranty.make === this.vehicle.make && makeWarranty.type === 'base');

                    this.$emit('update-vehicle', {
                        end_warranty_date: new ActivixDate(this.vehicle.in_service_date).addMonths(makeWarrantyBase.coverage_months).toString(),
                        end_warranty_mileage: makeWarrantyBase.mileage_max,
                        end_extended_warranty_date: new ActivixDate(this.vehicle.in_service_date).addMonths(this.makeWarranty.coverage_months).toString(),
                        end_extended_warranty_mileage: this.makeWarranty.mileage_max,
                    });
                }
            },
        },
    };
</script>
