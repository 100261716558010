<template>
    <activix-tooltip
        placement="right"
        :content="tooltip"
        v-if="isActive"
    >
        <button
            class="flex items-center h-18 p-6 bg-white | hover:bg-gray-200"
            :class="withLabel ? 'justify-start' : 'justify-center'"
            @click="openCreateAssociatedLead"
        >
            <span class="flex-shrink-0 flex place-items-stretch w-18 h-18 p-1.5">
                <span class="relative flex-1 flex justify-center items-center">
                    <icon class="text-lg" name="regular/add" />
                </span>
            </span>

            <span class="ml-1.5" v-if="withLabel">{{ label }}</span>
        </button>
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';

    import Account from '@/entities/Account';

    export default {
        props: {
            account: {
                type: Account,
                required: true,
            },
            defaultData: {
                type: Object,
                required: true,
            },
            relatedLeadId: {
                type: Number,
                required: true,
            },
            withLabel: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            isActive() {
                return this.authUser.hasAccessToEditAccount(this.account.id);
            },

            label() {
                return this.$t('clientCard.associatedLeads.createAssociatedLead');
            },

            tooltip() {
                if (this.withLabel) {
                    return '';
                }

                return this.label;
            },
        },

        methods: {
            openCreateAssociatedLead() {
                this.$eventBus.$emit('open-lead-create', {
                    ...this.defaultData,
                    addingAssociatedLead: true,
                    relatedLeadId: this.relatedLeadId,
                });
            },
        },
    };
</script>
