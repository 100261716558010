<template>
    <expandable-element
        :highlighted="highlighted"
        :opened-by-default="highlighted || openedByDefault"
    >
        <slot name="header">
            <vehicle-list-item-header
                slot="header"
                :account="account"
                :category="category"
                :readonly="readonly"
                :vehicle="vehicle"
                @delete-vehicle="$emit('delete-vehicle', $event)"
            />
        </slot>

        <slot name="content" />
    </expandable-element>
</template>

<script>
    import ExpandableElement from '@/components/presentational/boxes/ExpandableElement.vue';
    import VehicleListItemHeader from '@/components/container/lead/vehicles/VehicleListItemHeader.vue';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import LeadVehicleModality from '@/entities/LeadVehicleModality.js';
    import LeadVehicleTerm from '@/entities/LeadVehicleTerm.js';
    import ProgressState from '@/entities/ProgressState.js';

    export default {
        components: {
            ExpandableElement,
            VehicleListItemHeader,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            category: {
                type: String,
                required: true,
            },
            highlighted: {
                type: Boolean,
                default: false,
            },
            openedByDefault: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            lead() {
                return this.vehicle.lead;
            },
        },

        watch: {
            'vehicle.end_contract_date'(newValue) {
                if (this.vehicle.term) {
                    return;
                }

                const endContractDate = as_locale(newValue, 'new_date');
                const deliveredDate = as_locale(this.lead.delivered_date, 'delivered_date');
                const diffInMonths = endContractDate.diffInMonths(deliveredDate);

                if (diffInMonths <= 0) {
                    return;
                }

                const matchingTerm = LeadVehicleTerm.keys.find(term => term == diffInMonths);

                if (!matchingTerm) {
                    return;
                }

                this.updateVehicle({
                    data: {
                        term: matchingTerm,
                    },
                });
            },

            'vehicle.modality'(newValue, oldValue) {
                if (newValue === oldValue || (empty(newValue) && empty(oldValue))) {
                    return;
                }

                if (
                    !this.vehicle.isWanted ||
                    newValue !== LeadVehicleModality.CASH ||
                    this.lead.progress_state === ProgressState.CASH_DEAL
                ) {
                    return;
                }

                this.updateLead({
                    data: {
                        progress_state: ProgressState.CASH_DEAL,
                        lead_bank_id: null,
                    },
                });
            },

            'vehicle.term'(newValue, oldValue) {
                if (!newValue || newValue === oldValue) {
                    return;
                }

                if (this.vehicle.end_contract_date || !this.lead.delivered_date) {
                    return;
                }

                const deliveredDate = as_locale(this.lead.delivered_date, 'delivered_date');
                const endContractDate = deliveredDate.addMonths(newValue).toString();

                this.updateVehicle({
                    data: {
                        end_contract_date: endContractDate,
                    },
                });
            },
        },

        methods: {
            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },

            updateVehicle({ data, options = {} }) {
                this.$emit('update-vehicle', {
                    data,
                    id: this.vehicle.id,
                    options,
                });
            },
        },
    };
</script>
