<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
            <activix-tooltip :content="valueTooltip">
                <span>{{ $t('clientCard.vehicles.value') }}</span>
            </activix-tooltip>
        </input-label>

        <invisible-input
            class="flex-1"
            mask="money"
            :disabled="isDisabled"
            :readonly="readonly"
            :value="vehicle.value"
            @input="updateVehicle({ value: $event })"
            v-if="!applyCBBValue"
        />

        <activix-tooltip :content="tooltip" v-else>
            <div class="cursor-pointer inline-block" :class="{ 'text-red-500': minMaxValues }" @click="triggerAllowManualValue">
                {{ minMaxValues ? minMaxValues : toMoney(vehicleValue, 2) }}
            </div>
        </activix-tooltip>

        <activix-tooltip :content="$t('clientCard.selectCorrectVersion')" v-if="showMatchModal">
            <activix-button class="h-7 w-8 p-0 ml-3" type="white" @click="triggerMatchModal">
                <icon class="text-grey-600 text-lg" name="regular/car-actions-search-1" />
            </activix-button>
        </activix-tooltip>

        <activix-confirm-modal
            :content="$t('clientCard.changeValueCBB')"
            :opened.sync="modals.change.opened"
            :title="$t('clientCard.changeValueCBBTitle')"
            @approve="allowManualValue"
            @closed="closeModal"
        />

        <activix-confirm-modal
            :content="$t('clientCard.resetCBBValue')"
            :opened.sync="modals.resetCBB.opened"
            :title="$t('clientCard.resetCBBValueTitle')"
            @approve="resetCBBValue"
            @closed="closeModal"
        />

        <c-b-b-match-modal
            :opened.sync="modals.matchModal.opened"
            :vehicle="vehicle"
            @approve="setCBBValue"
            @closed="closeModal"
        />
    </flexible-input>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import CBBMatchModal from '@/components/modals/CBBMatchModal.vue';
    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput';

    import Account from '@/entities/Account';
    import EquityType from '@/entities/EquityType.js';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import VehicleVinMatchType from '@/entities/VehicleVinMatchType.js';

    export default {
        components: {
            CBBMatchModal,
            FlexibleInput,
            InputLabel,
            InvisibleInput,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        data() {
            return {
                canChangeValue: false,
                modals: {
                    change: {
                        opened: false,
                    },
                    matchModal: {
                        opened: false,
                    },
                    resetCBB: {
                        opened: false,
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            applyCBBValue() {
                return !empty(this.vehicle.vehicle_value) && this.vehicle.apply_cbb_value;
            },

            disabledByTradeReportAccess() {
                return this.account.trade_report && !this.authUser.trade_report && !this.authUser.isAdmin();
            },

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.disabledByTradeReportAccess) {
                    return this.$t('clientCard.blocked.tradeReport');
                }

                return '';
            },

            isDisabled() {
                return this.disabled || this.disabledByTradeReportAccess;
            },

            minMaxValues() {
                if (this.vehicle?.vehicle_value?.min_value &&
                    this.vehicle?.vehicle_value?.max_value &&
                    this.vehicleVin?.match === VehicleVinMatchType.PARTIAL
                ) {
                    return `${this.toMoney(this.vehicle.vehicle_value.min_value)} - ${this.toMoney(this.vehicle.vehicle_value.max_value)}`;
                }

                return null;
            },

            showMatchModal() {
                return !this.readonly &&
                    [VehicleVinMatchType.PARTIAL, VehicleVinMatchType.MANUAL].includes(this.vehicleVin?.match);
            },

            tooltip() {
                let condition =
                    this.vehicle.condition?.toLowerCase() || this.account.renewal_alert_options.equity_base;

                if (!condition) {
                    return '';
                }

                if (condition === 'extra_clean') {
                    condition = 'xclean';
                }

                if (!EquityType.keys.includes(condition)) {
                    return '';
                }

                const conditionString = this.$t(`accounts.edit.renewalOptions.equity.${condition}`);

                return !empty(this.vehicle.vehicle_value) && this.applyCBBValue
                    ? this.$t('clientCard.valueByCBB', [conditionString])
                    : '';
            },

            valueTooltip() {
                return this.tradeReportEnabled ? this.$t('clientCard.vehicles.valueTooltip') : '';
            },

            vehicleValue() {
                return this.vehicle.calculateVehicleValue();
            },

            vehicleVin() {
                return this.vehicle.vehicle_value ? this.vehicle.vehicle_value.vehicle_vin : null;
            },
        },
        watch: {
            'vehicle.value'(newValue) {
                if (this.readonly) {
                    return;
                }

                if (empty(newValue)) {
                    this.triggerResetCBBValue();
                }
            },
        },

        methods: {
            allowManualValue() {
                this.updateVehicle({ apply_cbb_value: false });
            },

            closeModal() {
                this.modals.change.opened = false;
                this.modals.matchModal.opened = false;
                this.modals.resetCBB.opened = false;
            },

            resetCBBValue() {
                this.updateVehicle({ apply_cbb_value: true });
            },

            setCBBValue(uvc) {
                this.$emit('update-vehicle-vin-value', {
                    uvc,
                    vehicleId: this.vehicle.id,
                });
            },

            triggerAllowManualValue() {
                if (this.readonly) {
                    return;
                }

                if (this.minMaxValues) {
                    this.triggerMatchModal();
                    return;
                }

                if (!empty(this.vehicle.vehicle_value) && this.applyCBBValue) {
                    this.modals.change.opened = true;
                }
            },

            triggerMatchModal() {
                if (this.vehicle.apply_cbb_value) {
                    this.modals.matchModal.opened = true;
                }
            },

            triggerResetCBBValue() {
                if (!empty(this.vehicle.vehicle_value) && !this.applyCBBValue) {
                    this.modals.resetCBB.opened = true;
                }
            },

            updateVehicle(data) {
                this.$emit('update-vehicle', data);
            },
        },
    };
</script>
