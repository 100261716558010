<template>
    <div class="flex flex-grow justify-center items-center" v-if="showListLoading">
        <activix-spinner />
    </div>

    <div v-else>
        <note-list-item
            :account="account"
            :context-note-data="contextNoteData"
            :depth="depth"
            :last-note-id-in-group="lastNoteIdInGroupComputed"
            :note="note"
            :note-type-filter="noteTypeFilter"
            :parent-note="parentNote"
            :readonly="readonly"
            :root-note-id="rootNoteId || note.id"
            :key="note.id"
            @trigger-deleting="$emit('trigger-deleting', $event)"
            @trigger-editing="$emit('trigger-editing', $event)"
            @trigger-replying="$emit('trigger-replying', $event)"
            v-for="note in notes"
        />

        <div class="flex justify-center border-t p-3" v-if="showMoreItemsLoading">
            <activix-spinner :size="22" :line-size="2" />
        </div>
    </div>
</template>

<script>
    import NoteListItem from '@/components/container/lead/notes/NoteListItem.vue';

    import Account from '@/entities/Account';
    import Comment from '@/entities/Comment';

    export default {
        components: {
            NoteListItem,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            contextNoteData: {
                type: Object,
                required: true,
            },
            depth: {
                type: Number,
                default: 0,
            },
            lastNoteIdInGroup: {
                type: Number,
                default: null,
            },
            noteTypeFilter: {
                type: Array,
                default: () => [],
            },
            notes: {
                type: Array,
                required: true,
            },
            parentNote: {
                type: Comment,
                default: () => new Comment(),
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            rootNoteId: {
                type: Number,
                default: null,
            },
            showListLoading: {
                type: Boolean,
                default: false,
            },
            showMoreItemsLoading: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            lastNoteIdInGroupComputed() {
                if (this.lastNoteIdInGroup) {
                    return this.lastNoteIdInGroup;
                }

                return this.getLastNoteIdInGroup(this.notes);
            },
        },

        methods: {
            getLastNoteIdInGroup(notes) {
                if (this.depth !== 1) {
                    return null;
                }

                const lastNote = notes.slice(-1).pop();

                if (!lastNote.children.length) {
                    return lastNote.id;
                }

                return this.getLastNoteIdInGroup(lastNote.children);
            },
        },
    };
</script>
