<template>
    <div class="flex flex-col overflow-y-auto rounded-sm bg-gray-100">
        <table-box>
            <template slot="header">
                <caption>
                    <h5 class="m-6 my-3 text-gray-600 uppercase font-semibold">
                        {{ $t('clientCard.boxes.vehicles.pricingOverview') }}
                    </h5>
                </caption>
                <t-header class="bg-transparent" slot="header">
                    <th scope="col" class="w-1/2 text-sm font-semibold uppercase text-gray-600">
                        {{ $t('clientCard.vehicles.description') }}
                    </th>
                    <th scope="col" class="w-1/2 whitespace-nowrap text-sm font-semibold uppercase text-gray-600">
                        <span class="mr-2">
                            {{ $t('clientCard.vehicles.subtotal') }}
                        </span>
                    </th>
                </t-header>
            </template>

            <t-content slot="content">
                <tr class="transition hover:bg-gray-200" v-if="fieldIsVisible('price')">
                    <td>
                        <input-label slot="label" class="flex items-center" :blocked-reason="internalBlockedReason">
                            {{ $t('clientCard.vehicles.price') }}
                        </input-label>
                    </td>
                    <td>
                        <invisible-input
                            mask="money"
                            text-align="right"
                            :disabled="disabled || isLinkedToInventoryWithPrice"
                            :readonly="readonly"
                            :value="vehicle.price"
                            @input="$emit('update-vehicle', { price: $event })"
                        />
                    </td>
                </tr>
                <tr class="transition hover:bg-gray-200" v-if="fieldIsVisible('accessories')">
                    <td>
                        {{ $t('clientCard.vehicles.accessories') }}
                    </td>
                    <td>
                        <invisible-input
                            mask="money"
                            text-align="right"
                            :disabled="disabled"
                            :readonly="readonly"
                            :value="vehicle.accessories"
                            @input="$emit('update-vehicle', { accessories: $event })"
                        />
                    </td>
                </tr>
                <tr class="transition hover:bg-gray-200" v-if="fieldIsVisible('preparation')">
                    <td>
                        {{ $t('clientCard.vehicles.preparation') }}
                    </td>
                    <td>
                        <invisible-input
                            mask="money"
                            text-align="right"
                            :disabled="disabled"
                            :readonly="readonly"
                            :value="vehicle.preparation"
                            @input="$emit('update-vehicle', { preparation: $event })"
                        />
                    </td>
                </tr>
                <tr class="transition hover:bg-gray-200" v-if="fieldIsVisible('documentation')">
                    <td>
                        {{ $t('clientCard.vehicles.documentation') }}
                    </td>
                    <td>
                        <invisible-input
                            mask="money"
                            text-align="right"
                            :disabled="disabled"
                            :readonly="readonly"
                            :value="vehicle.documentation"
                            @input="$emit('update-vehicle', { documentation: $event })"
                        />
                    </td>
                </tr>
            </t-content>

            <tfoot slot="footer">
                <tr v-if="fieldIsVisible('total')">
                    <th scope="row" class="font-semibold uppercase text-gray-700">
                        {{ $t('clientCard.vehicles.total') }}
                    </th>
                    <td class="font-semibold uppercase text-gray-700">
                        <span class="mr-2.5">
                            {{ toCurrency(vehicle.totalPrice) }}
                        </span>
                    </td>
                </tr>

                <tr>
                    <th scope="row" class="font-semibold uppercase text-gray-700">
                        {{ $t('clientCard.vehicles.profit') }}
                    </th>
                    <td class="uppercase text-gray-700">
                        <invisible-input
                            class="profit-field"
                            mask="money"
                            text-align="right"
                            :disabled="disabled"
                            :readonly="readonly"
                            :value="vehicle.profit"
                            @input="$emit('update-vehicle', { profit: $event })"
                        />
                    </td>
                </tr>
            </tfoot>
        </table-box>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import InvisibleInput from '@/components/container/input/InvisibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';

    import TContent from '@/components/presentational/table/TContent.vue';
    import THeader from '@/components/presentational/table/THeader.vue';
    import TableBox from '@/components/presentational/table/TableBox.vue';

    import Account from '@/entities/Account';
    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            InvisibleInput,
            InputLabel,
            TContent,
            THeader,
            TableBox,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            stockManagementEnabled: {
                type: Boolean,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            internalBlockedReason() {
                if (this.blockedReason) {
                    return this.blockedReason;
                }

                if (this.readonly) {
                    return '';
                }

                if (this.isLinkedToInventoryWithPrice) {
                    return this.$t('clientCard.blocked.vehicleStock');
                }

                return '';
            },

            isLinkedToInventory() {
                return !empty(this.vehicle.inventory_unit) && this.stockManagementEnabled;
            },

            isLinkedToInventoryWithPrice() {
                return this.isLinkedToInventory && this.stockManagementEnabled && this.vehicle.inventory_unit.price;
            },

            lead() {
                return this.vehicle.lead;
            },

            shouldShowProfit() {
                if (this.authUser.isAdmin()) {
                    return true;
                }

                if (this.account.sale_table_options && !this.account.sale_table_options.hide_profit) {
                    return true;
                }

                if (this.authUser.isDirector() || this.authUser.isCommercialDirector()) {
                    return true;
                }

                if (this.authUser.isAdvisor() && this.authUser.id === this.lead.user_id) {
                    return true;
                }

                if (
                    this.authUser.id === this.lead.bdc_user_id &&
                    (this.authUser.isBdc() || this.authUser.isBdcDirector())
                ) {
                    return true;
                }

                return false;
            },
        },

        methods: {
            fieldIsVisible(field) {
                const vehicleSection = `${this.vehicle.type}_vehicle`;

                return this.account.client_card_fields[vehicleSection][field];
            },
        },
    };
</script>

<style scoped>
    table.table-fitted td:last-child,
    table.table-fitted th:last-child {
        @apply text-right;
    }

    .profit-field >>> input {
        @apply font-semibold;
    }
</style>
