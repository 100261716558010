<template>
    <activix-tooltip :content="tooltip">
        <button
            class="relative flex flex-grow items-center justify-center"
            :class="[{
                'text-gray-500 cursor-not-allowed hover:bg-transparent': disabled,
                'text-gray-700 hover:bg-gray-300': !disabled,
            }, buttonClassName]"
            @click="!disabled && $emit('click')"
            v-popover:popover
        >
            <span class="flex items-center justify-center gap-2">
                <span
                    class="absolute"
                    :class="[indicatorColor, indicatorClassName, {
                        'left-0 h-6 w-1.5 rounded-r': verticalLayout,
                        'top-0 w-6 h-1.5 rounded-b': !verticalLayout,
                    }]"
                    v-if="indicator"
                />

                <slot name="before-icon" />

                <icon
                    :class="[iconSizeClassName, iconClassName]"
                    :name="iconName"
                />

                <slot name="after-icon" />
            </span>

            <el-popover
                placement="bottom-end"
                trigger="click"
                ref="popover"
                @click.stop
                v-if="$slots.popoverContent || $scopedSlots.popoverContent"
            >
                <slot name="popoverContent" :close-popover="closePopover" />
            </el-popover>
        </button>
    </activix-tooltip>
</template>

<script>
    export const indicatorTypes = ['danger', 'info', 'success', 'warning'];

    export default {
        props: {
            buttonClassName: {
                type: [String, Array, Object],
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            iconClassName: {
                type: [String, Array, Object],
                default: '',
            },
            iconName: {
                type: String,
                required: true,
            },
            iconSizeClassName: {
                type: [String, Array, Object],
                default: 'text-xl',
            },
            indicator: {
                type: Boolean,
                default: false,
            },
            indicatorClassName: {
                type: [String, Array, Object],
                default: '',
            },
            indicatorType: {
                type: String,
                default: 'info',
                validator: value => indicatorTypes.includes(value),
            },
            tooltip: {
                type: String,
                default: '',
            },
            verticalLayout: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            indicatorColor() {
                return {
                    danger: 'bg-red-500',
                    info: 'bg-blue-500',
                    success: 'bg-green-500',
                    warning: 'bg-orange-500',
                }[this.indicatorType];
            },
        },

        methods: {
            closePopover() {
                this.$refs.popover.doClose();
            },
        },
    };
</script>
