<template>
    <el-popover
        popper-class="bg-white border-0 rounded-lg"
        placement="right"
        trigger="hover"
        :close-delay="0"
        :disabled="isExpanded"
    >
        <template #reference>
            <router-link
                class="relative flex items-center px-6 py-3 gap-3 | transition hover:bg-gray-200"
                :replace="true"
                :to="{
                    name: 'leads.update',
                    params: { id: lead.id },
                    query: $route.query.hasOwnProperty('new-lead-page') ? { 'new-lead-page': $route.query['new-lead-page'] } : {}
                }"
            >
                <span class="absolute left-0 inset-y-0 z-40 flex items-center" :class="activeLeadTabClassNames">
                    <span class="w-1.5 h-12 rounded-r bg-blue-500" />
                </span>
                <associated-lead-item-icon :lead="lead" />
                <associated-lead-item-info :lead="lead" v-if="isExpanded" />
            </router-link>
        </template>

        <div class="p-6">
            <associated-lead-item-info :lead="lead" />
        </div>
    </el-popover>
</template>

<script>
    import AssociatedLeadItemIcon from '@/components/container/lead/AssociatedLeadItemIcon.vue';
    import AssociatedLeadItemInfo from '@/components/container/lead/AssociatedLeadItemInfo.vue';

    import Lead from '@/entities/Lead';

    export default {
        components: {
            AssociatedLeadItemIcon,
            AssociatedLeadItemInfo,
        },

        props: {
            active: {
                type: Boolean,
                default: false,
            },
            isExpanded: {
                type: Boolean,
                required: true,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            activeLeadTabClassNames() {
                if (this.active) {
                    return 'opacity-100';
                }

                return 'opacity-0';
            },
        },
    };
</script>
