<template>
    <div class="flex items-start p-3 gap-3 border-b border-gray-200 bg-gray-50 | xl:p-6 | print:hidden">
        <note-avatar class="hidden sm:block" :user="authUser" />

        <div class="flex-1 flex flex-col rounded-lg shadow-lg bg-white relative overflow-hidden">
            <note-context
                :icon="noteContextIcon"
                :message="noteContextMessage"
                :value="isReplying ? contextNote.content : ''"
                @cancel="onCancel"
                v-if="isReplying || isEditing"
            />
            <button
                class="text-gray-600 absolute z-40 top-0 right-0 p-2 | hover:text-gray-700"
                @click="onCancel"
                v-tooltip="cancelButtonTooltip"
                v-else-if="isActive"
            >
                <icon name="bold/remove-circle" />
            </button>

            <div class="w-full h-24" v-show="audioOpened">
                <audio-recorder
                    class="z-10 relative"
                    :opened="audioOpened"
                    :comment="isEditing ? contextNote : bufferNote"
                    @input="handleAudioFile"
                    @isRecording="handleIsRecording"
                    @errorRecording="audioOpened = false"
                />
            </div>

            <audio-player
                :file="bufferNote.media"
                :editing="true"
                class="w-full"
                v-if="bufferNote.media"
            />

            <template v-if="isTextNote">
                <textarea-autosize
                    class="p-3 rounded-t-lg w-full block"
                    :autosize="true"
                    :max-height="350"
                    :min-height="84"
                    :placeholder="$t('clientCard.addCommentOrUpload')"
                    ref="textArea"
                    @keydown.meta.enter.native="onSave"
                    v-model="bufferNote.content"
                    v-if="isEditing"
                />
                <dropzone @file-dropped="setFiles" v-else>
                    <textarea-autosize
                        class="p-3 rounded-t-lg w-full block"
                        :autosize="true"
                        :max-height="350"
                        :min-height="84"
                        :placeholder="$t('clientCard.addCommentOrUpload')"
                        ref="textArea"
                        @keydown.meta.enter.native="onSave"
                        v-model="bufferNote.content"
                    />
                </dropzone>
            </template>

            <div class="flex justify-between items-center gap-3 p-3 border-t border-gray-200 rounded-b-lg bg-gray-100">
                <div class="flex flex-grow item-center gap-3">
                    <mention-input
                        :account="account"
                        :disabled="isSaving"
                        :selected-item-ids.sync="bufferNote.alert_users"
                    />

                    <activix-tooltip :content="recordingTooltip">
                        <div>
                            <activix-button
                                class="rounded"
                                :disabled="isSaving || isEditing"
                                type="white"
                                @click="microphoneClick"
                            >
                                <icon name="regular/microphone" />
                            </activix-button>
                        </div>
                    </activix-tooltip>

                    <activix-tooltip :content="fileUploadTooltip">
                        <label
                            class="btn | relative rounded btn-md bg-white"
                            :class="{ disabled: !fileUploadActive }"
                        >
                            <input
                                :id="`file_${noteKey}`"
                                class="hidden z-10 relative"
                                :class="{'cursors-not-allowed': audioOpened}"
                                multiple="multiple"
                                :name="`file_${noteKey}`"
                                type="file"
                                ref="file"
                                @change="uploadAttachments()"
                                v-if="fileUploadActive"
                            />
                            <icon
                                name="regular/attachment"
                                :class="[
                                    hasFiles ? 'link-blue' : 'link-grey-dark',
                                    { disabled: !fileUploadActive }
                                ]"
                            />
                            <span class="pl-1 text-xs" v-if="hasFiles"> {{ bufferNote.file.length }} </span>
                        </label>
                    </activix-tooltip>

                    <div class="flex flex-grow items-center" v-if="!audioFile && !bufferNote.media && bufferNote.file_url">
                        <a class="flex items-center" :href="bufferNote.file_url" target="_blank">
                            <icon name="regular/common-file-download" />
                            <span class="ml-2 truncate max-w-32 2xl:max-w-64">{{ getFileName(bufferNote.file_url) }}</span>
                        </a>
                        <icon
                            class="link-grey-light ml-3 | hover:text-red-500"
                            :name="$icons.trash"
                            @click="bufferNote.file_url = ''"
                        />
                    </div>
                </div>

                <activix-tooltip :content="$t('clientCard.privateNote')">
                    <div class="flex items-center justify-center space-x-1.5">
                        <icon class="text-gray-600" :name="privateNoteIcon" />
                        <activix-switcher
                            color="blue"
                            size="x-small"
                            :disabled="isSaving"
                            :value="bufferNote.private"
                            @input="bufferNote.private = $event"
                        />
                    </div>
                </activix-tooltip>

                <activix-button
                    class="rounded"
                    :disabled="saveDisabled"
                    :loading="isSaving"
                    type="primary"
                    @click="onSave"
                >
                    {{ $t('clientCard.save') }}
                </activix-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useLayoutStore } from '@/store/modules/layout/store';

    import Vapor from 'laravel-vapor';

    import AudioPlayer from '@/components/audio/AudioPlayer.vue';
    import AudioRecorder from '@/components/audio/AudioRecorder';
    import NoteAvatar from '@/components/container/lead/notes/NoteAvatar';
    import Dropzone from '@/components/container/input/Dropzone';
    import MentionInput from '@/components/container/input/note/MentionInput';
    import NoteContext from '@/components/container/lead/notes/NoteContext';

    import Account from '@/entities/Account';
    import Comment from '@/entities/Comment';
    import NoteAction from '@/entities/NoteAction';
    import Role from '@/entities/Role';

    export default {
        components: {
            AudioPlayer,
            AudioRecorder,
            Dropzone,
            MentionInput,
            NoteAvatar,
            NoteContext,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            contextNote: {
                type: Comment,
                default: null,
            },
            noteAction: {
                type: NoteAction,
                default: NoteAction.CREATE,
            },
        },

        data() {
            return {
                audioFile: null,
                audioOpened: false,
                bufferNote: new Comment(),
                isRecording: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),

            alertUsersWithoutGroups() {
                return this.bufferNote.alert_users.filter(userId => String(userId).indexOf('G') === -1);
            },

            cancelButtonTooltip() {
                if (this.audioOpened) {
                    return this.$t('clientCard.recordDelete');
                }

                return '';
            },

            fileUploadActive() {
                return this.isTextNote && !this.isEditing && !this.isSaving;
            },

            fileUploadTooltip() {
                if (this.isEditing) {
                    return this.$t('clientCard.boxes.notes.notAvailableInEditMode');
                }

                return this.$t('clientCard.attachFiles');
            },

            hasFiles() {
                return this.bufferNote?.file?.length > 0;
            },

            hasUpdatedMentions() {
                if (!this.isEditing) {
                    return false;
                }

                const originalMentions = this.contextNote.alert_users;
                const updatedMentions = this.bufferNote.alert_users;

                if (updatedMentions.length !== originalMentions.length) {
                    return true;
                }

                return updatedMentions.some(userId => !originalMentions.includes(userId));
            },

            isAdding() {
                return [NoteAction.CREATE, NoteAction.REPLY].includes(this.noteAction);
            },

            isActive() {
                return this.audioOpened ||
                    this.audioFile ||
                    this.bufferNote.content ||
                    this.bufferNote.file?.length ||
                    this.isEditing ||
                    this.isReplying;
            },

            isEditing() {
                return this.noteAction === NoteAction.EDIT;
            },

            isReplying() {
                return this.noteAction === NoteAction.REPLY;
            },

            isSaving() {
                return this.$wait.is('creating.note') || this.$wait.is('updating.note');
            },

            isTextNote() {
                return !this.audioFile && !this.audioOpened && !this.bufferNote.media;
            },

            noteContextMessage() {
                if (this.isReplying) {
                    const contextFullname = this.contextNote.fullname || '';
                    const [contextFirstName = '', contextLastName = ''] = contextFullname.split(' ', 2);

                    if (!contextLastName || contextLastName === '-') {
                        return `${this.$t('clientCard.replyingTo')} ${contextFirstName}`;
                    }

                    const contextUsername = `${contextFirstName.charAt(0).toUpperCase()}. ${contextLastName}`;

                    return `${this.$t('clientCard.replyingTo')} ${contextUsername}`;
                }

                if (this.isEditing) {
                    return this.$t('clientCard.editing');
                }

                return '';
            },

            noteContextIcon() {
                if (this.isReplying) {
                    return 'regular/email-action-reply';
                }

                if (this.isEditing) {
                    return 'regular/pencil-1';
                }

                return '';
            },

            noteKey() {
                if (!this.bufferNote.parent_id) {
                    return 'new';
                }

                return `${this.bufferNote.parent_id}_${this.bufferNote.id || 'new'}`;
            },

            privateNoteIcon() {
                if (this.bufferNote.private) {
                    return 'regular/view-off';
                }

                return 'regular/view-1';
            },

            recordingTooltip() {
                if (this.isEditing) {
                    return this.$t('clientCard.boxes.notes.notAvailableInEditMode');
                }

                return this.$t('clientCard.record');
            },

            saveDisabled() {
                if (this.isAdding) {
                    return this.$wait.is('creating.note') ||
                        (
                            !this.bufferNote.content &&
                            empty(this.bufferNote.file) &&
                            empty(this.audioFile)
                        );
                }

                return this.$wait.is('updating.note') ||
                    (
                        this.bufferNote.private === this.contextNote.private &&
                        this.bufferNote.content === this.contextNote.content &&
                        !this.hasUpdatedMentions
                    );
            },
        },

        watch: {
            audioOpened(newValue) {
                if (newValue) {
                    this.bufferNote.private = this.authUser.role_id === Role.COMMERCIAL;
                } else {
                    this.bufferNote.private = false;
                }
            },

            'bufferNote.alert_users'() {
                if (!this.bufferNote.parent_user_id || this.bufferNote.parent_user_id === this.authUser.id) {
                    return;
                }

                if (!this.bufferNote.alert_users.some(userId => userId === this.bufferNote.parent_user_id)) {
                    this.bufferNote.alert_users.push(this.bufferNote.parent_user_id);
                }
            },

            contextNote() {
                if (this.isEditing) {
                    this.bufferNote = new Comment({ ...this.contextNote });
                } else if (this.isReplying) {
                    this.bufferNote = new Comment({
                        parent_id: this.contextNote.id,
                        parent_user_id: this.contextNote.user_id,
                    });
                } else {
                    this.bufferNote = new Comment();
                }
            },
        },

        methods: {
            focus() {
                const textArea = this.$refs.textArea?.$el;

                if (!textArea) {
                    return;
                }

                textArea.focus({ preventScroll: true });

                const currentScrollTop = window.scrollY;
                const itemPosition = this.$el.getBoundingClientRect().top + currentScrollTop;
                const mainHeaderOffset = this.headerHeight + this.bodySpacingTop;
                const newScrollTop = itemPosition - mainHeaderOffset;

                if (newScrollTop >= currentScrollTop) {
                    return;
                }

                window.scroll({
                    top: newScrollTop,
                    behavior: 'smooth',
                });
            },

            handleAudioFile(file) {
                this.audioFile = file;
            },

            handleIsRecording(status) {
                this.isRecording = status;
            },

            microphoneClick() {
                this.setFiles([]);
                this.audioOpened = !this.audioOpened;
            },

            onCancel() {
                this.resetData();

                this.$emit('cancel');
            },

            onSave() {
                if (this.saveDisabled) {
                    return;
                }

                if (this.isEditing) {
                    this.sendEditedNote();
                } else {
                    this.sendNote();
                }
            },

            resetData() {
                this.audioOpened = false;
                this.bufferNote = new Comment();
            },

            async sendAudioFile() {
                const duration = Math.floor(this.audioFile.duration);
                const audioFile = new File([this.audioFile.blobs], `${this.audioFile.name}.mp3`);

                const responseVapor = await Vapor.store(audioFile, {
                    progress: progress => {
                        this.uploadProgress = Math.round(progress * 100);
                    },
                    baseURL: this.$axios.defaults.baseURL,
                    headers: this.$axios.defaults.headers.common,
                });

                this.$emit('submit', {
                    parentId: this.bufferNote.parent_id,
                    key: responseVapor.key,
                    alert_users: this.alertUsersWithoutGroups,
                    private: this.bufferNote.private,
                    duration,
                });

                this.audioFile = null;
                this.audioOpened = false;

                this.resetData();
            },

            sendNote() {
                if (this.audioFile) {
                    this.sendAudioFile();
                } else {
                    let note = {
                        parent_id: this.bufferNote.parent_id,
                        alert_users: this.alertUsersWithoutGroups,
                        alert: this.bufferNote.alert,
                        alert_sms: this.bufferNote.alert_sms,
                        alert_email: this.bufferNote.alert_email,
                        content: this.bufferNote.content,
                        file: this.bufferNote.file,
                        private: this.bufferNote.private,
                        user_id: this.authUser.id,
                        request_user_id: this.bufferNote.request_user_id,
                    };

                    if (note.file?.length === 1) {
                        note = { ...note, file: note.file[0], content: note.content };
                    } else if (note.file?.length > 1) {
                        this.bufferNote.file.forEach(file => {
                            note = { ...note, file, content: this.bufferNote.content };
                            this.$emit('submit', note);
                        });

                        return;
                    }

                    this.$emit('submit', note);

                    this.resetData();
                }
            },

            sendEditedNote() {
                const noteData = {
                    id: this.bufferNote.id,
                    content: this.bufferNote.content,
                    file_url: this.bufferNote.file_url,
                    private: this.bufferNote.private,
                    alert_users: this.alertUsersWithoutGroups,
                };

                this.$emit('submit', noteData);

                this.resetData();
            },

            setFiles(files) {
                this.bufferNote.file = [...files];
            },

            uploadAttachments(attachment) {
                this.bufferNote.file = attachment || this.$refs.file.files;
                this.bufferNote.file = [...this.bufferNote.file];
            },
        },
    };
</script>
