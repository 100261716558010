<template>
    <div :class="wrapperClassName">
        <div class="flex flex-col gap-6" :key="colIndex" v-for="(fieldsToColumn, colIndex) in fieldsToColumns">
            <template v-for="(field, fieldIndex) in fieldsToColumn">
                <activix-tooltip
                    :content="field.tooltip"
                    :key="`${colIndex}-${fieldIndex}`"
                    v-if="field.rawComponent"
                >
                    <component
                        :is="field.component"
                        v-bind="field.props"
                        v-on="field.events"
                        v-if="field.rawComponent"
                    />
                </activix-tooltip>

                <flexible-input
                    :key="`${colIndex}-${fieldIndex}`"
                    v-bind="field"
                    v-else
                >
                    <icon
                        slot="label-suffix"
                        class="text-base"
                        :name="field.icon"
                        v-if="field.icon"
                    />
                </flexible-input>
            </template>
        </div>

        <div class="col-span-full" v-if="boxFields.length">
            <template v-for="(field, fieldIndex) in boxFields">
                <component
                    :is="field.component"
                    :key="fieldIndex"
                    v-bind="field.props"
                    v-on="field.events"
                    v-if="field.rawComponent"
                />
                <flexible-input
                    :key="fieldIndex"
                    v-bind="field"
                    v-else
                >
                    <icon
                        slot="label-suffix"
                        class="text-base"
                        :name="field.icon"
                        v-if="field.icon"
                    />
                </flexible-input>
            </template>
        </div>
    </div>
</template>

<script>
    import FlexibleInput from '@/components/container/input/FlexibleInput';

    export default {
        components: { FlexibleInput },

        props: {
            balanceColumns: {
                type: Boolean,
                default: false,
            },
            columnCount: {
                type: Number,
                default: 2,
                validator: value => value <= 6,
            },
            fields: {
                type: Array,
                required: true,
            },
        },

        computed: {
            boxFields() {
                return this.visibleFields.filter(field => {
                    return field.display === 'box';
                });
            },

            gridFields() {
                return this.visibleFields.filter(field => {
                    return field.display === 'line' || typeof field.display === 'undefined';
                });
            },

            visibleFields() {
                return this.fields.filter(field => {
                    return field.visible || typeof field.visible === 'undefined';
                });
            },

            wrapperClassName() {
                return [
                    'grid grid-cols-1 gap-6',
                    {
                        'md:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2': this.columnCount === 2,
                        'md:grid-cols-2 lg:grid-cols-3': this.columnCount === 3,
                        'md:grid-cols-3 lg:grid-cols-4': this.columnCount === 4,
                        'md:grid-cols-4 lg:grid-cols-5': this.columnCount === 5,
                        'md:grid-cols-5 lg:grid-cols-6': this.columnCount === 6,
                    },
                ];
            },

            fieldsToColumns() {
                const columnsItemsCount = [];
                const fieldsCol = [];
                const unsortedFields = [];

                for (let i = 0; i < this.columnCount; i++) {
                    columnsItemsCount[i] = 0;
                    fieldsCol[i] = [];
                }

                this.gridFields.forEach(field => {
                    if (typeof field.column === 'undefined') {
                        unsortedFields.push(field);
                    } else if (field.column > this.columnCount) {
                        unsortedFields.push(field);
                    } else {
                        fieldsCol[field.column - 1].push(field);
                        columnsItemsCount[field.column - 1]++;
                    }
                });

                unsortedFields.forEach(field => {
                    const lowestColumn = columnsItemsCount.indexOf(Math.min(...columnsItemsCount));

                    fieldsCol[lowestColumn].push(field);
                    columnsItemsCount[lowestColumn]++;
                });

                if (!this.balanceColumns) {
                    return fieldsCol;
                }

                const flattenFields = fieldsCol.flat();
                const itemsByCol = Math.ceil(flattenFields.length / this.columnCount);
                const colIndexThreshold = Array(this.columnCount)
                    .fill(0)
                    .map((_, colIndex) => itemsByCol + (colIndex * itemsByCol));

                const balancedFieldsCol = [];

                flattenFields.forEach((column, index) => {
                    const balancedFieldCol = colIndexThreshold.findIndex(threshold => index < threshold);

                    balancedFieldsCol[balancedFieldCol] = balancedFieldsCol[balancedFieldCol] || [];
                    balancedFieldsCol[balancedFieldCol].push(column);
                });

                return balancedFieldsCol;
            },
        },
    };
</script>
