<template>
    <box>
        <template #header>
            <slot name="header" />
        </template>

        <template #content>
            <slot name="user-assignations" />

            <div class="flex flex-col items-start p-6 pb-0 gap-3 | sm:flex-row sm:justify-between sm:items-center">
                <h3 class="box-title">
                    {{ $t('clientCard.boxes.general.details') }}
                </h3>

                <activix-pills class="hidden sm:inline-flex" :pills="pills" v-model="activePill" />

                <activix-select
                    class="w-full | block sm:hidden"
                    :options="pills"
                    value-key="name"
                    v-model="activePill"
                />
            </div>

            <box-content v-if="$scopedSlots.tabs">
                <slot name="tabs" :activePill="activePill" />
            </box-content>
        </template>
    </box>
</template>

<script>
    import { ActivixPills, ActivixSelect } from '@activix/ui';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent';

    export default {
        components: {
            ActivixPills,
            ActivixSelect,
            Box,
            BoxContent,
        },

        props: {
            pills: {
                type: Array,
                required: true,
            },
        },

        data: () => ({
            activePill: null,
        }),

        mounted() {
            this.activePill = this.pills[0].name;
        },
    };
</script>
