<template>
    <details
        :open="openedByDefault"
        :class="{ highlighted }"
    >
        <summary
            class="
                group
                rounded-lg shadow-md bg-white
                cursor-pointer transform transition duration-300 hover:shadow-xl
            "
        >
            <slot name="header" />
        </summary>

        <slot />
    </details>
</template>

<script>
    export default {
        props: {
            highlighted: {
                type: Boolean,
                default: false,
            },
            openedByDefault: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style scoped>
    details > summary {
        list-style: none;
    }

    details > summary::-webkit-details-marker {
        display: none;
    }

    details[open] > summary > *:last-child,
    details[open] > summary {
        @apply rounded-b-none !important;
    }

    details[open] > summary:hover {
        @apply translate-y-0 shadow-md !important;
    }
</style>
