<template>
    <div class="flex flex-col">
        <slot name="label" />

        <input-label v-if="label && !$slots.label">
            {{ label }}
        </input-label>

        <div class="flex items-center space-x-1.5" :class="{ 'h-9': fixedHeight }">
            <slot name="input" />
        </div>
    </div>
</template>

<script>
    import InputLabel from '@/components/presentational/input/InputLabel.vue';

    export default {
        components: { InputLabel },

        props: {
            fixedHeight: {
                type: Boolean,
                default: true,
            },
            label: {
                type: [String],
                required: false,
            },
        },
    };
</script>
