<template>
    <box-header-icon
        icon-name="regular/filter-1"
        :indicator="hasFiltersApplied"
        :tooltip="$t('dashboards.filter')"
    >
        <template #popoverContent="{ closePopover }">
            <filters
                :filters="filters"
                @apply="applyFilters($event, closePopover)"
                @reset="resetFilters(closePopover)"
            />
        </template>
    </box-header-icon>
</template>

<script>
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon';
    import Filters from '@/components/container/Filters';

    import Account from '@/entities/Account';
    import LeadVehicleFilter from '@/entities/LeadVehicleFilter';
    import LeadVehicleStockState from '@/entities/LeadVehicleStockState';

    const initialFilters = {
        polestarState: null,
        sold: null,
        stockNumber: '',
        stockState: [],
        vin: '',
    };

    export default {
        components: { BoxHeaderIcon, Filters },

        props: {
            account: {
                required: true,
                type: Account,
            },
            vehicles: {
                required: true,
                type: Array,
            },
        },

        data: () => ({
            filterValues: initialFilters,
        }),

        computed: {
            filteredVehicles() {
                return this.vehicles.filter(this.vehicleMatchesFilters);
            },

            filters() {
                return {
                    polestarState: {
                        invalid: !this.isPolestarAccount,
                        label: this.$t('clientCard.boxes.vehicles.filters.polestarVehicleState'),
                        options: [
                            {
                                value: LeadVehicleFilter.ORDER,
                                text: this.$tc('clientCard.order'),
                            },
                            {
                                value: LeadVehicleFilter.TEST_DRIVE,
                                text: this.$tc('clientCard.roadTest'),
                            },
                            {
                                value: LeadVehicleFilter.TEST_DRIVE_AYL,
                                text: this.$tc('clientCard.roadTestAYL'),
                            },
                            {
                                value: LeadVehicleFilter.TEST_DRIVE_TDM,
                                text: this.$tc('clientCard.roadTestTDM'),
                            },
                        ],
                        tooltip: this.polestarStateFilterTooltip,
                        type: 'select',
                        value: this.filterValues.polestarState,
                    },
                    sold: {
                        label: this.$t('clientCard.boxes.vehicles.filters.sold'),
                        type: 'bool',
                        value: this.filterValues.sold,
                    },
                    stockNumber: {
                        label: this.$t('clientCard.boxes.vehicles.filters.stockNumber'),
                        type: 'text',
                        value: this.filterValues.stockNumber,
                    },
                    stockState: {
                        label: this.$t('clientCard.boxes.vehicles.filters.stockState'),
                        multiple: true,
                        options: LeadVehicleStockState.selectOptions('value', 'text'),
                        type: 'select',
                        value: this.filterValues.stockState,
                    },
                    vin: {
                        label: this.$t('clientCard.boxes.vehicles.filters.vin'),
                        type: 'text',
                        value: this.filterValues.vin,
                    },
                };
            },

            hasFiltersApplied() {
                return Object.entries(this.filterValues).some(([name, value]) => {
                    const initialFilter = initialFilters[name];

                    if (initialFilter instanceof Array) {
                        const selectedValues = value || [];

                        return selectedValues.length !== initialFilter.length ||
                            selectedValues.some(selectedValue => !initialFilter.includes(selectedValue));
                    }

                    return value !== initialFilter;
                });
            },

            isPolestarAccount() {
                return this.account.isPolestar();
            },

            polestarOrders() {
                return this.vehicles.filter(vehicle => vehicle.isPolestarOrder);
            },

            polestarOrdersTooltip() {
                if (this.polestarOrders.length) {
                    return `${this.polestarOrders.length} ${this.$tc('clientCard.order', this.polestarOrders.length)}`;
                }

                return this.$t('clientCard.orderEmpty');
            },

            polestarStateFilterTooltip() {
                return `${this.polestarOrdersTooltip} / ${this.polestarTestDrivesTooltip}`;
            },

            polestarTestDrives() {
                return this.vehicles.filter(vehicle => vehicle.isPolestarTestDrive);
            },

            polestarTestDrivesTooltip() {
                if (this.polestarTestDrives.length) {
                    return `${this.polestarTestDrives.length} ${this.$tc('clientCard.roadTest', this.polestarTestDrives.length)}`;
                }

                return this.$t('clientCard.roadTestEmpty');
            },
        },

        watch: {
            filteredVehicles: {
                immediate: true,
                handler() {
                    this.$emit('vehicles-filtered', {
                        filteredVehicles: this.filteredVehicles,
                        hasFiltersApplied: this.hasFiltersApplied,
                    });
                },
            },
        },

        methods: {
            applyFilters(selectedFilters, closeFilterMenu) {
                closeFilterMenu();

                this.updateFilters({
                    ...this.filterValues,
                    ...selectedFilters,
                });

                this.$emit('apply');
            },

            clearFilters() {
                this.updateFilters(initialFilters);
            },

            resetFilters(closeFilterMenu) {
                closeFilterMenu();

                this.clearFilters();

                this.$emit('reset');
            },

            updateFilters(filters) {
                this.$set(this, 'filterValues', filters);
            },

            vehicleMatchesFilters(vehicle) {
                return (!this.isPolestarAccount || this.vehicleMatchesPolestarFilters(vehicle)) &&
                    this.vehicleMatchesSold(vehicle) &&
                    this.vehicleMatchesStockNumber(vehicle) &&
                    this.vehicleMatchesStockState(vehicle) &&
                    this.vehicleMatchesVin(vehicle);
            },

            vehicleMatchesPolestarFilters(vehicle) {
                if (!this.isPolestarAccount) {
                    return true;
                }

                switch (this.filterValues.polestarState) {
                    case LeadVehicleFilter.ORDER:
                        return vehicle.isPolestarOrder;

                    case LeadVehicleFilter.TEST_DRIVE:
                        return vehicle.isPolestarTestDrive;

                    case LeadVehicleFilter.TEST_DRIVE_AYL:
                        return vehicle.isPolestarTestDrive && vehicle.isPolestarTestDriveAYL;

                    case LeadVehicleFilter.TEST_DRIVE_TDM:
                        return vehicle.isPolestarTestDrive && vehicle.isPolestarTestDriveTDM;

                    case LeadVehicleFilter.ALL:
                    default:
                        return true;
                }
            },

            vehicleMatchesSold(vehicle) {
                if (this.filterValues.sold === null) {
                    return true;
                }

                return vehicle.sold === this.filterValues.sold;
            },

            vehicleMatchesStockNumber(vehicle) {
                if (!this.filterValues.stockNumber) {
                    return true;
                }

                const vehicleStock = (vehicle.stock || '').toLowerCase();

                return vehicleStock.includes(this.filterValues.stockNumber.toLowerCase());
            },

            vehicleMatchesStockState(vehicle) {
                if (!this.filterValues.stockState.length) {
                    return true;
                }

                return this.filterValues.stockState.includes(vehicle.stock_state);
            },

            vehicleMatchesVin(vehicle) {
                if (!this.filterValues.vin) {
                    return true;
                }

                const vehicleVin = (vehicle.vin || '').toLowerCase();

                return vehicleVin.includes(this.filterValues.vin.toLowerCase());
            },
        },
    };
</script>
