<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="blockedReason">
            {{ $t('clientCard.boxes.vehicles.yearsRange') }}
        </input-label>

        <div class="flex flex-grow items-center gap-3">
            <invisible-multiselect
                :disabled="disabled"
                :nullable="true"
                :options="yearsRange"
                placeholder="min"
                :readonly="readonly"
                :value="(vehicle.year_min || '').toString()"
                @input="$emit('update-vehicle', { year_min: $event })"
            />

            <invisible-multiselect
                :animated="false"
                :disabled="disabled"
                :nullable="true"
                :options="yearsRange"
                placeholder="max"
                :readonly="readonly"
                :value="(vehicle.year_max || '').toString()"
                @input="$emit('update-vehicle', { year_max: $event })"
            />
        </div>
    </flexible-input>
</template>

<script>
    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleMultiselect from '@/components/container/input/InvisibleMultiselect.vue';

    import Moment from '@/value-objects/Moment';
    import { range } from 'lodash-es';

    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleMultiselect,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },

        computed: {
            yearsRange() {
                const fromYear = 1899; // fromYear is exclusive
                const toYear = Moment.now().year() + 3;
                return range(toYear, fromYear, -1)
                    .map(year => {
                        const yearString = year.toString();

                        return {
                            value: yearString,
                            text: yearString,
                        };
                    });
            },
        },
    };
</script>
