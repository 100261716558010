<template>
    <div class="space-y-3 | lg:space-y-6 xl:space-y-3 2xl:space-y-6">
        <vehicle-list-item
            :account="account"
            :category="category"
            :highlighted="vehicle.id === highlightedItemId"
            :opened-by-default="vehicles.length === 1"
            :readonly="readonly"
            :vehicle="vehicle"
            :key="vehicle.id"
            :ref="`vehicleItem_${vehicle.id}`"
            @delete-vehicle="$emit('delete-vehicle', $event)"
            @export-vehicle="$emit('export-vehicle', $event)"
            @update-lead="$emit('update-lead', $event)"
            @update-vehicle="$emit('update-vehicle', $event)"
            @update-vehicle-vin-value="$emit('update-vehicle-vin-value', $event)"
            v-for="vehicle in vehicles"
        >
            <template #header>
                <slot name="item-header" :vehicle="vehicle" />
            </template>
            <template #content>
                <slot name="item-content" :vehicle="vehicle" />
            </template>
        </vehicle-list-item>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';
    import { useLayoutStore } from '@/store/modules/layout/store';

    import VehicleListItem from '@/components/container/lead/vehicles/VehicleListItem.vue';

    import Account from '@/entities/Account';

    export default {
        components: {
            VehicleListItem,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            category: {
                type: String,
                default: 'car',
                validator: value => ['car', 'powersport'].includes(value),
            },
            highlightedItemId: {
                type: Number,
                default: null,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicles: {
                type: Array,
                required: true,
            },
        },

        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),

            highlightedItemScrollOffset() {
                return this.headerHeight + this.bodySpacingTop + 20;
            },
        },

        watch: {
            async highlightedItemId() {
                if (!this.highlightedItemId) {
                    return;
                }

                await this.$nextTick();

                const highlightedItemRef = this.$refs[`vehicleItem_${this.highlightedItemId}`];

                if (!highlightedItemRef?.[0]) {
                    return;
                }

                const itemPosition = highlightedItemRef[0].$el.getBoundingClientRect().top + window.scrollY;

                window.scroll({
                    top: itemPosition - this.highlightedItemScrollOffset,
                    behavior: 'smooth',
                });
            },
        },

        methods: {
            ...mapActions(useClientCardStore, ['setVehicles', 'setVehicleMakes']),
        },

        async mounted() {
            await this.setVehicles();
            await this.setVehicleMakes();
        },
    };
</script>
