<template>
    <flexible-input>
        <input-label slot="label" class="flex items-center" :blocked-reason="blockedReason">
            {{ $t('clientCard.boxes.vehicles.lengthRange') }}
        </input-label>

        <div class="flex flex-grow items-center gap-3">
            <invisible-input
                :disabled="disabled"
                mask="## m"
                placeholder="min"
                :readonly="readonly"
                :value="vehicle.length_min"
                @input="$emit('update-vehicle', { length_min: $event })"
            />

            <invisible-input
                :animated="false"
                :disabled="disabled"
                mask="## m"
                placeholder="max"
                :readonly="readonly"
                :value="vehicle.length_max"
                @input="$emit('update-vehicle', { length_max: $event })"
            />
        </div>
    </flexible-input>
</template>

<script>
    import FlexibleInput from '@/components/container/input/FlexibleInput.vue';
    import InputLabel from '@/components/presentational/input/InputLabel.vue';
    import InvisibleInput from '@/components/container/input/InvisibleInput.vue';

    import LeadVehicle from '@/entities/LeadVehicle.js';

    export default {
        components: {
            FlexibleInput,
            InputLabel,
            InvisibleInput,
        },

        props: {
            blockedReason: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
        },
    };
</script>
