<template>
    <input-group :fixed-height="fixedHeight" v-if="visible">
        <slot name="label" slot="label" />

        <template #label v-if="label && !$slots.label">
            <input-label class="flex items-center gap-x-2" :blocked-reason="blockedReason">
                <activix-tooltip :content="labelTooltip">
                    <span>{{ label }}</span>
                </activix-tooltip>

                <slot name="label-suffix">
                    <div class="flex gap-2 normal-case" v-if="badges.length">
                        <template v-if="badges.length">
                            <activix-tooltip :content="badge.tooltip" :key="index" v-for="(badge, index) in badges">
                                <div class="flex items-center px-3 py-0.5 gap-1.5 rounded-full text-sm text-gray-800 bg-gray-300">
                                    <icon :name="badge.iconName" v-if="badge.iconName" />
                                    <span v-text="badge.text" v-if="badge.text" />
                                </div>
                            </activix-tooltip>
                        </template>

                        <activix-tooltip :content="labelIconTooltip" v-if="labelIconName">
                            <icon :name="labelIconName" :class="labelIconClassName" />
                        </activix-tooltip>
                    </div>
                </slot>
            </input-label>
        </template>

        <slot slot="input">
            <activix-tooltip :content="tooltip" v-if="tooltip">
                <component
                    :is="component"
                    v-bind="props"
                    v-on="events"
                />
            </activix-tooltip>
            <component
                :is="component"
                v-bind="props"
                v-on="events"
                v-else
            />
        </slot>
    </input-group>
</template>

<script>
    import InputLabel from '@/components/presentational/input/InputLabel';
    import InputGroup from '@/components/presentational/input/InputGroup';

    export default {
        components: { InputGroup, InputLabel },

        props: {
            badges: {
                type: Array,
                default: () => [],
            },
            blockedReason: {
                type: String,
            },
            component: {
                type: [Object, String],
            },
            events: {
                type: Object,
                default: () => ({}),
            },
            fixedHeight: {
                type: Boolean,
                default: true,
            },
            label: {
                type: String,
                default: '',
            },
            labelIconName: {
                type: String,
                default: '',
            },
            labelIconClassName: {
                type: [String, Array, Object],
                default: null,
            },
            labelIconTooltip: {
                type: String,
                default: '',
            },
            labelTooltip: {
                type: String,
                default: '',
            },
            props: {
                type: Object,
                default: () => ({}),
            },
            tooltip: {
                type: String,
                default: '',
            },
            visible: {
                type: Boolean,
                default: true,
            },
        },

        created() {
            if (!this.component && !this.$slots.default) {
                throw new Error('FlexibleInput needs a specific component instance');
            }
        },
    };
</script>
