<template>
    <div class="rounded-b-lg">
        <slot>
            <div class="flex items-center">
                <div class="flex flex-1">
                    <slot name="footer-left" />
                </div>

                <div class="flex flex-shrink-0">
                    <slot name="footer-center" />
                </div>

                <div class="flex justify-end flex-1">
                    <slot name="footer-right" />
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
    export default {};
</script>
