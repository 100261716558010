<template>
    <div class="relative truncate">
        <div class="flex items-center">
            <span
                class="overflow-hidden overflow-ellipsis font-semibold text-gray-800 | transition duration-200"
                :title="vehicleText"
                v-text="vehicleText"
            />
        </div>

        <div class="flex items-center">
            <span
                class="text-grey-700 |transition duration-200"
                v-text="date"
            />
            <span
                class="label | ml-2 rounded-full text-white leading-none capitalize"
                :class="vehicleStatusColor"
                v-text="vehicleStatusText"
            />
        </div>
    </div>
</template>

<script>
    import Lead from '@/entities/Lead';
    import LeadType from '@/entities/LeadType';
    import LeadVehicle from '@/entities/LeadVehicle';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            date() {
                return this.lead.contextDate.toHumanShort(false);
            },

            vehicle() {
                if (this.lead.isService()) {
                    return new LeadVehicle(this.lead.serviceVehicle);
                }

                if (this.lead.isOfType(LeadType.RENEWAL)) {
                    return new LeadVehicle(this.lead.renewalVehicle);
                }

                return new LeadVehicle(this.lead.getSoldOrFirstWantedVehicle());
            },

            vehicleText() {
                return [this.vehicle.year, this.vehicle.make, this.vehicle.model]
                    .filter(part => (part || '').trim())
                    .join(' ');
            },

            vehicleStatusColor() {
                if (!this.vehicle.sold) {
                    return '';
                }

                if (this.lead.isDelivered()) {
                    return 'bg-green-500';
                }

                return 'bg-blue-500';
            },

            vehicleStatusText() {
                if (!this.vehicle.sold) {
                    return '';
                }

                if (this.lead.isDelivered()) {
                    return this.$t('clientCard.delivered');
                }

                return this.$t('clientCard.sold');
            },
        },
    };
</script>
