<template>
    <div class="flex flex-grow items-center justify-center">
        <div class="sticky" :style="positionStyle">
            <div
                class="flex flex-grow items-center justify-center"
                :class="{
                    'flex-row-reverse pb-12': horizontal,
                    'flex-col': !horizontal,
                }"
            >
                <activix-illustrations
                    class="pointer-events-none"
                    :class="{
                        'w-48 px-0 ml-0 | md:px-2 md:ml-6': horizontal,
                        'w-56 px-6': !horizontal
                    }"
                    :name="svg"
                />
                <div class="flex flex-col" :class="{ 'items-center': !horizontal }">
                    <div class="mb-2 text-gray-800 text-2xl font-medium" v-text="title" />

                    <div class="text-gray-600 text-lg" :class="{ 'mb-6': showAdd }" v-text="description" />

                    <activix-tooltip :content="addTooltip" v-if="showAdd">
                        <activix-spinner
                            :class="{ 'mr-auto': horizontal }"
                            :size="20"
                            v-if="loading"
                        />

                        <activix-button
                            type="white"
                            @click="$emit('add-item')"
                            v-else
                        >
                            <icon class="text-blue-500 w-4 h-4 mr-3" name="bold/add" />

                            <div class="uppercase text-gray-600 tracking-wide" v-text="$t('general.add')" />
                        </activix-button>
                    </activix-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store';

    export default {
        props: {
            addTooltip: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            horizontal: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            showAdd: {
                type: Boolean,
                default: false,
            },
            svg: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                default: '',
            },
        },

        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),

            positionStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top}px`,
                    bottom: '1.5rem',
                };
            },
        },
    };
</script>
