<template>
    <el-pagination
        layout="total, ->, prev, pager, next"
        :pager-count="5"
        :background="false"
        :current-page="currentPage"
        :page-size="5"
        :page-sizes="[]"
        :total="totalItems"
        @current-change="$emit('page-changed', $event)"
    />
</template>

<script>
    export default {
        props: {
            currentPage: {
                type: Number,
                required: true,
            },
            totalItems: {
                type: Number,
                required: true,
            },
        },
    };
</script>
