<template>
    <activix-modal
        size="sm"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.subscriptionManagement') }}
            </h4>
        </template>

        <template slot="body">
            <div class="space-y-6">
                <div class="flex justify-between items-center" :class="{ 'text-gray-500': actionDisabled }">
                    <div class="flex justify-center items-center">
                        <icon class="mr-2 text-xl" :name="$icons.phone" />
                        <span>
                            {{ $t('clientCard.call') }}
                        </span>
                    </div>

                    <activix-tooltip :content="phoneSubscriptionTooltip">
                        <div class="flex items-center space-x-3">
                            <span>
                                {{ phoneSubscriptionText }}
                            </span>
                            <activix-switcher
                                :value="phoneSubscriptionState"
                                :disabled="actionDisabled"
                                @input="onChange('call', $event)"
                            />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="flex justify-between items-center" :class="{ 'text-gray-500': actionDisabled }">
                    <div class="flex justify-center items-center">
                        <icon class="mr-2 text-xl" :name="$icons.email" />
                        <span>
                            {{ $t('clientCard.email') }}
                        </span>
                    </div>

                    <activix-tooltip :content="emailSubscriptionTooltip">
                        <div class="flex items-center space-x-3">
                            <span>
                                {{ emailSubscriptionText }}
                            </span>
                            <activix-switcher
                                :value="emailSubscriptionState"
                                :disabled="actionDisabled"
                                @input="onChange('email', $event)"
                            />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="flex justify-between items-center" :class="{ 'text-gray-500': actionDisabled }">
                    <div class="flex justify-center items-center">
                        <icon class="mr-2 text-xl" :name="$icons.sms" />
                        <span>
                            {{ $t('clientCard.smsTheClient') }}
                        </span>
                    </div>

                    <activix-tooltip :content="smsSubscriptionTooltip">
                        <div class="flex items-center space-x-3">
                            <span>
                                {{ smsSubscriptionText }}
                            </span>
                            <activix-switcher
                                :value="smsSubscriptionState"
                                :disabled="actionDisabled"
                                @input="onChange('sms', $event)"
                            />
                        </div>
                    </activix-tooltip>
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>

            <activix-button type="primary" @click="save">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            unsubscribedToAllDate: {
                type: String,
                default: null,
            },
            unsubscribedToEmailDate: {
                type: String,
                default: null,
            },
            unsubscribedToPhoneDate: {
                type: String,
                default: null,
            },
            unsubscribedToSmsDate: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                subscriptionStates: {},
            };
        },

        computed: {
            actionDisabled() {
                return this.guest || this.isUnsubscribedToAll;
            },

            emailSubscriptionState() {
                return this.subscriptionStates.email ?? !this.isUnsubscribedToEmail;
            },

            emailSubscriptionText() {
                if (this.isUnsubscribedToAll) {
                    return this.$t('clientCard.isUnsubscribedAll');
                }

                if (this.emailSubscriptionState) {
                    return this.$t('clientCard.isSubscribed');
                }

                return this.$t('clientCard.isNotSubscribed');
            },

            emailSubscriptionTooltip() {
                if (this.unsubscribedToAllTooltip) {
                    return this.unsubscribedToAllTooltip;
                }

                if (this.isUnsubscribedToEmail) {
                    return this.$t('clientCard.unsubscribedSince', [
                        new ActivixDate(this.unsubscribedToEmailDate).toHumanShort(),
                    ]);
                }

                return '';
            },

            isUnsubscribedToAll() {
                return !!this.unsubscribedToAllDate;
            },

            isUnsubscribedToEmail() {
                return this.isUnsubscribedToAll || !!this.unsubscribedToEmailDate;
            },

            isUnsubscribedToPhone() {
                return this.isUnsubscribedToAll || !!this.unsubscribedToPhoneDate;
            },

            isUnsubscribedToSms() {
                return this.isUnsubscribedToAll || !!this.unsubscribedToSmsDate;
            },

            phoneSubscriptionState() {
                return this.subscriptionStates.call ?? !this.isUnsubscribedToPhone;
            },

            phoneSubscriptionText() {
                if (this.isUnsubscribedToAll) {
                    return this.$t('clientCard.isUnsubscribedAll');
                }

                if (this.phoneSubscriptionState) {
                    return this.$t('clientCard.isSubscribed');
                }

                return this.$t('clientCard.isNotSubscribed');
            },

            phoneSubscriptionTooltip() {
                if (this.unsubscribedToAllTooltip) {
                    return this.unsubscribedToAllTooltip;
                }

                if (this.isUnsubscribedToPhone) {
                    return this.$t('clientCard.unsubscribedSince', [
                        new ActivixDate(this.unsubscribedToPhoneDate).toHumanShort(),
                    ]);
                }

                return '';
            },

            smsSubscriptionState() {
                return this.subscriptionStates.sms ?? !this.isUnsubscribedToSms;
            },

            smsSubscriptionText() {
                if (this.isUnsubscribedToAll) {
                    return this.$t('clientCard.isUnsubscribedAll');
                }

                if (this.smsSubscriptionState) {
                    return this.$t('clientCard.isSubscribed');
                }

                return this.$t('clientCard.isNotSubscribed');
            },

            smsSubscriptionTooltip() {
                if (this.unsubscribedToAllTooltip) {
                    return this.unsubscribedToAllTooltip;
                }

                if (this.isUnsubscribedToSms) {
                    return this.$t('clientCard.unsubscribedSince', [
                        new ActivixDate(this.unsubscribedToSmsDate).toHumanShort(),
                    ]);
                }

                return '';
            },

            unsubscribedToAllTooltip() {
                if (!this.isUnsubscribedToAll) {
                    return '';
                }

                const unsubscribedToAllDate = new ActivixDate(this.unsubscribedToAllDate);

                if (unsubscribedToAllDate.year === 2222) {
                    return this.$t('clientCard.clientIsAllUnsubscribedForever');
                }

                return this.$t('clientCard.clientIsAllUnsubscribed', [
                    unsubscribedToAllDate.toHumanShort(false),
                ]);
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },

            onChange(type, value) {
                this.$set(this.subscriptionStates, type, value);
            },

            onClosed() {
                this.subscriptionStates = {};
            },

            save() {
                const data = {};
                const now = new ActivixDate('now').toString();

                for (const [field, state] of Object.entries(this.subscriptionStates)) {
                    data[`unsubscribe_${field}`] = !state;
                    data[`unsubscribe_${field}_date`] = state ? null : now;
                }

                this.update(data);

                this.close();
            },

            update(data) {
                this.$emit('update', data);
            },
        },
    };
</script>
