<template>
    <activix-modal
        size="xs"
        :opened="opened"
        @open="onOpen"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.dndTitle') }}
            </h4>
        </template>

        <template slot="body">
            <div class="text-center | mb-4" v-if="!indefinitely">
                <date-time-picker :date-only="true" :start-date="currentTime" v-model="unsubscribedToAllDateInternal">
                    <activix-button
                        class="w-12 attached-l"
                        type="default"
                        slot="after-button"
                        @click="unsubscribedToAllDateInternal = null"
                        v-if="unsubscribedToAllDateInternal"
                    >
                        <icon :name="$icons.trash" class="inline-flex" />
                    </activix-button>
                </date-time-picker>
            </div>

            <div class="text-center">
                <activix-checkbox v-model="indefinitely">
                    {{ $t('clientCard.indefinitely') }}
                </activix-checkbox>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>

            <activix-button type="primary" @click="submit">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import DateTimePicker from '@/components/inputs/DateTimePicker.vue';

    export default {
        components: {
            DateTimePicker,
        },

        props: {
            opened: {
                required: true,
                type: Boolean,
            },
            unsubscribedToAllDate: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                currentTime: now().toString(),
                indefinitely: false,
                submitted: false,
                unsubscribedToAllDateInternal: '',
            };
        },

        watch: {
            indefinitely() {
                if (this.indefinitely) {
                    this.unsubscribedToAllDateInternal = locale_date('2222-01-01').toString();
                } else {
                    this.unsubscribedToAllDateInternal = null;
                }
            },

            unsubscribedToAllDate(newValue) {
                this.indefinitely = locale_date(newValue).year() === 2222;
            },
        },

        methods: {
            submit() {
                this.submitted = true;

                this.close();
            },

            close() {
                this.$emit('update:opened', false);
                this.$emit('close');
            },

            onOpen() {
                this.unsubscribedToAllDateInternal = this.unsubscribedToAllDate;
            },

            onClosed() {
                if (this.submitted) {
                    this.$emit('update', { unsubscribe_all_date: this.unsubscribedToAllDateInternal });
                }

                this.submitted = false;
                this.unsubscribedToAllDateInternal = null;
            },
        },
    };
</script>
