<template>
    <div
        class="
            relative flex flex-grow items-stretch rounded-sm shadow-none bg-white bg-opacity-0
            | focus-within:bg-opacity-100
        "
        :class="[positioningClassName, {
            'focus-within:z-50 focus-within:shadow focus:bg-opacity-100': !disabled,
            '-ml-2.5': animated,
            'text-gray-500': disabled,
        }]"
        :style="{
            height: `${baseHeight}px`,
        }"
    >
        <textarea-autosize
            class="
                flex-1 px-2.5 py-1.5 overflow-y-hidden rounded-sm placeholder-gray-500 resize-none
                transition duration-150
                ring-1 ring-gray-200 ring-opacity-0
                !bg-white border border-gray-300 border-opacity-0
                focus-within:ring-blue-500 focus-within:ring-opacity-100 focus-within:border-blue-500 focus-within:border-opacity-100
            "
            :class="{
                'hover:border-opacity-100 focus-within:border-opacity-100': !disabled,
            }"
            :autosize="autoExpand && focused"
            :disabled="disabled"
            :placeholder="placeholder"
            :readonly="readonly"
            :rows="rows"
            :value="value"
            :max-height="252"
            ref="textarea"
            @blur.native="onBlur"
            @focus.native="onFocus"
        />
    </div>
</template>

<script>
    export default {
        props: {
            animated: {
                type: Boolean,
                default: true,
            },
            autoExpand: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '---',
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            rows: {
                type: Number,
                default: 4,
            },
            value: {
                type: String,
                default: '',
            },
        },

        data: () => ({
            focused: false,
            baseHeight: null,
        }),

        computed: {
            positioningClassName() {
                return [
                    'transition-all duration-150',
                    { 'focus-within:ml-0': !this.disabled },
                ];
            },
        },

        watch: {
            rows: {
                immediate: true,
                async handler() {
                    await this.$nextTick();

                    this.baseHeight = this.$refs.textarea.$el.clientHeight;
                },
            },
        },

        methods: {
            onFocus() {
                this.focused = true;
            },

            onBlur() {
                this.focused = false;

                const textArea = this.$refs.textarea.$el;
                textArea.style.height = 'auto';
                textArea.style.width = 'auto';

                if (this.value === textArea.value) {
                    return;
                }

                this.$emit('input', textArea.value);
            },
        },
    };
</script>
