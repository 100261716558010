<template>
    <box class="bg-gray-100" :class="{ loading }">
        <template #header>
            <box-header class="bg-white rounded-t-lg">
                <div class="flex items-center justify-center">
                    <span>{{ title }}</span>
                </div>

                <template #right>
                    <box-header-icon
                        :disabled="loading"
                        :icon-name="orderIconName"
                        :tooltip="orderDirectionTooltip"
                        @click="toggleOrderDirection"
                    />

                    <slot name="filters" />

                    <box-header-icon
                        :disabled="loading"
                        icon-name="regular/add"
                        :tooltip="addVehicleTooltip"
                        @click="$emit('add-vehicle')"
                        v-if="!readonly && canAddVehicle"
                    />
                </template>
            </box-header>
        </template>

        <template slot="content">
            <box-content class="!p-3 rounded-b-lg | lg:!p-6 xl:!p-3 2xl:!p-6">
                <transition
                    enter-active-class="transition duration-150 transform"
                    enter-class="opacity-0 -translate-y-2"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition duration-150 transform"
                    leave-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 translate-y-2"
                    mode="out-in"
                >
                    <vehicle-list
                        :account="account"
                        :category="vehicleCategory"
                        :highlighted-item-id="highlightedItemId"
                        :readonly="readonly"
                        :vehicles="vehicles"
                        @delete-vehicle="$emit('delete-vehicle', $event)"
                        @export-vehicle="$emit('export-vehicle', $event)"
                        @update-lead="updateLead"
                        @update-vehicle="updateVehicle"
                        @update-vehicle-vin-value="$emit('update-vehicle-vin-value', $event)"
                        v-if="vehicles.length"
                    >
                        <template #item-header="{ vehicle }">
                            <slot name="item-header" :vehicle="vehicle" />
                        </template>
                        <template #item-content="{ vehicle }">
                            <slot name="item-content" :vehicle="vehicle" />
                        </template>
                    </vehicle-list>
                    <empty-state
                        svg="civil-car-1"
                        :title="emptyStateTitle"
                        :description="emptyStateDescription"
                        v-else
                    />
                </transition>
            </box-content>

            <slot name="modals" />
        </template>

        <template #footer v-if="pagination.totalItemsCount > pagination.perPage">
            <box-footer class="flex p-3 bg-white | lg:p-6 xl:p-3 2xl:p-6">
                <vehicle-pagination
                    class="flex-grow"
                    :current-page="pagination.currentPage"
                    :total-items="pagination.totalItemsCount"
                    @page-changed="changePage"
                />
            </box-footer>
        </template>
    </box>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import Box from '@/components/presentational/boxes/Box';
    import BoxContent from '@/components/presentational/boxes/BoxContent';
    import BoxFooter from '@/components/presentational/boxes/BoxFooter';
    import BoxHeader from '@/components/presentational/boxes/BoxHeader';
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import VehicleList from '@/components/container/lead/vehicles/VehicleList.vue';
    import VehiclePagination from '@/components/container/lead/vehicles/VehiclePagination.vue';

    import Account from '@/entities/Account';
    import LeadVehicleType from '@/entities/LeadVehicleType.js';

    export default {
        components: {
            Box,
            BoxContent,
            BoxFooter,
            BoxHeader,
            BoxHeaderIcon,
            EmptyState,
            VehicleList,
            VehiclePagination,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            canAddVehicle: {
                type: Boolean,
                default: true,
            },
            filters: {
                type: Object,
                default: () => ({}),
            },
            filterValues: {
                type: Object,
                default: () => ({}),
            },
            hasFiltersApplied: {
                type: Boolean,
                default: false,
            },
            highlightedItemId: {
                type: Number,
                default: null,
            },
            pagination: {
                type: Object,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            type: {
                required: true,
                validator(value) {
                    return LeadVehicleType.keys.includes(value);
                },
            },
            vehicles: {
                type: Array,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            addVehicleTooltip() {
                if (this.loading || this.guest) {
                    return '';
                }

                return this.$t('clientCard.addVehicle');
            },

            emptyStateDescription() {
                let translations = {};

                if (this.hasFiltersApplied) {
                    translations = {
                        exchange: 'clientCard.emptyState.vehicleExchangeNoMatchDescription',
                        wanted: 'clientCard.emptyState.vehicleWantedNoMatchDescription',
                    };
                } else {
                    translations = {
                        exchange: 'clientCard.emptyState.vehicleExchangeDescription',
                        wanted: 'clientCard.emptyState.vehicleWantedDescription',
                    };
                }

                return this.$t(translations[this.type]);
            },

            emptyStateTitle() {
                const translations = {
                    exchange: 'clientCard.emptyState.vehicleExchangeTitle',
                    wanted: 'clientCard.emptyState.vehicleWantedTitle',
                };

                return this.$t(translations[this.type]);
            },

            loading() {
                return this.$wait.is('customer.initializing.vehicles') ||
                    this.$wait.is(`fetching.${this.vehicleTypeKey}`) ||
                    this.$wait.is(`adding.${this.vehicleTypeKey}`);
            },

            orderDirectionTooltip() {
                if (this.pagination.orderDirection === 'asc') {
                    return this.$t('sorting.oldestFirst');
                }

                return this.$t('sorting.newestFirst');
            },

            orderIconName() {
                if (this.pagination.orderDirection === 'asc') {
                    return 'regular/reorder-up';
                }

                return 'regular/reorder-down';
            },

            title() {
                const vehicleCount = this.pagination.totalItemsCount || 1;

                if (this.type === LeadVehicleType.EXCHANGE) {
                    return this.$tc('clientCard.boxes.vehicles.currentVehiclesTitle', vehicleCount);
                }

                return this.$tc('clientCard.boxes.vehicles.wantedVehiclesTitle', vehicleCount);
            },

            vehicleCategory() {
                return this.account.power_sport ? 'powersport' : 'car';
            },

            vehicleTypeKey() {
                return `${this.type}_vehicles`;
            },
        },

        methods: {
            async changePage(newPage, newOrderDirection = null) {
                this.$emit('change-page', { newPage, newOrderDirection });
            },

            async toggleOrderDirection() {
                const newOrderDirection = this.pagination.orderDirection === 'asc' ? 'desc' : 'asc';

                await this.changePage(1, newOrderDirection);
            },

            updateLead({ data, options = {} }) {
                this.$emit('update-lead', { data, options });
            },

            updateVehicle({ data, id, options = {} }) {
                this.$emit('update-vehicle', { data, id, options });
            },
        },
    };
</script>
