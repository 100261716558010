<template>
    <div
        class="relative flex flex-grow items-stretch bg-white bg-opacity-0 | focus-within:bg-opacity-100"
        :class="[positioningClassName, {
            '-ml-2.5': animated && textAlign !== 'right',
            'flex-grow-0 flex-shrink-0': !animated,
            'text-gray-500': disabled,
        }]"
    >
        <activix-input
            :id="computedId"
            class="flex-grow items-center"
            :class="[positioningClassName, { '-ml-px': animated && textAlign !== 'right' }]"
            :input-class-name="{ 'text-right': textAlign === 'right' }"
            padding-class-name="px-2.5 py-1.5"
            autocomplete="off"
            :placeholder="placeholder"
            :type="type"
            :before="before"
            :after="after"
            :error="error"
            :readonly="readonly"
            :disabled="disabled"
            :invisible="true"
            :mask="mask"
            :maxlength="maxlength"
            ref="input"
            @blur="onBlur"
            @click="$emit('click')"
            @focus="$emit('focus')"
            @keydown.enter="onEnter"
            @keydown.esc="onEsc"
            v-model="internalValue"
        >
            <template #before>
                <slot name="before-input" />
            </template>

            <template #after>
                <activix-tooltip :content="buttonTooltip" v-if="buttonIcon">
                    <button
                        class="
                            flex justify-center items-center px-3 border-l border-gray-300 border-opacity-0
                            hover:bg-blue-500 hover:text-white hover:border-opacity-0
                            focus:bg-blue-500 focus:text-white
                            group-hover:border-opacity-100
                            group-focus-within:border-opacity-100
                            transition duration-150
                        "
                        :class="buttonDisabled ? 'disabled' : ''"
                        tabindex="-1"
                        @click="$emit('button-click')"
                    >
                        <icon :class="buttonIconClassName" :name="buttonIcon" />
                    </button>
                </activix-tooltip>
                <slot name="after-input" v-else />
            </template>
        </activix-input>

        <slot name="after" />
    </div>
</template>

<script>
    import { ActivixInput } from '@activix/ui';
    import { uniqueId } from 'lodash-es';

    export default {
        components: {
            ActivixInput,
        },

        props: {
            after: {
                default: '',
                type: String,
            },
            animated: {
                type: Boolean,
                default: true,
            },
            before: {
                default: '',
                type: String,
            },
            buttonDisabled: {
                type: Boolean,
                default: false,
            },
            buttonIcon: {
                default: '',
                type: String,
            },
            buttonIconClassName: {
                default: '',
                type: String,
            },
            buttonTooltip: {
                default: '',
                type: String,
            },
            disabled: {
                default: false,
                type: Boolean,
            },
            error: {
                default: false,
                type: Boolean,
            },
            id: {
                default: '',
                type: String,
            },
            mask: {
                default: '',
                type: String,
            },
            maxlength: {
                default: null,
                type: Number,
            },
            placeholder: {
                default: '---',
                type: String,
            },
            readonly: {
                default: false,
                type: Boolean,
            },
            textAlign: {
                default: 'left',
                type: String,
                validator: value => ['left', 'right'].includes(value),
            },
            type: {
                default: 'text',
                type: String,
            },
            value: {
                default: '',
                type: [String, Number],
            },
        },

        data: () => ({
            cancelling: false,
            internalValue: '',
        }),

        computed: {
            computedId() {
                return this.id || uniqueId('input-');
            },

            positioningClassName() {
                return [
                    'transition-all duration-150',
                    { 'focus-within:ml-0': !this.disabled },
                ];
            },

            returnValue() {
                return this.internalValue === '' ? null : this.internalValue;
            },
        },

        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    if (newValue === this.internalValue) {
                        return;
                    }

                    this.syncInternalValue(newValue);
                },
            },
        },

        methods: {
            blur() {
                this.$refs.input.blur();
            },

            focus() {
                this.$refs.input.focus();
            },

            onBlur() {
                this.$emit('blur');

                this.update();
            },

            onEnter() {
                this.$refs.input.blur();
            },

            onEsc() {
                this.cancelling = true;
                this.$refs.input.blur();
            },

            syncInternalValue(value) {
                this.internalValue = value === null ? '' : value;
            },

            update() {
                if (this.cancelling) {
                    this.syncInternalValue(this.value);
                    this.cancelling = false;
                    return;
                }

                if (this.readonly || this.value === this.returnValue) {
                    return;
                }

                this.$emit('input', this.returnValue);
            },
        },
    };
</script>
