<template>
    <div class="flex items-center mb-1 text-gray-800 tracking-wider uppercase font-semibold text-sm">
        <slot />

        <activix-tooltip :content="blockedReason" v-if="blockedReason">
            <icon name="regular/lock-1" class="ml-1.5 cursor-help" />
        </activix-tooltip>
    </div>
</template>

<script>
    export default {
        props: {
            blockedReason: {
                type: String,
            },
        },
    };
</script>
