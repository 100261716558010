<template>
    <div class="flex flex-col gap-y-6 rounded-b-lg bg-white w-96">
        <div class="flex flex-col gap-y-6 px-6 pt-6">
            <div
                :key="name"
                v-for="(filter, name) in availableFilters"
            >
                <activix-label class="block" :label="filter.label" />

                <activix-tooltip :content="filter.tooltip">
                    <activix-select
                        class="flex-1"
                        label-key="text"
                        value-key="value"
                        :multiple="filter.multiple || false"
                        :nullable="true"
                        :nullable-option-label="$t('filters.all')"
                        :options="getSelectOptions(filter)"
                        :placeholder="$t('filters.all')"
                        :value="getValue(name, filter)"
                        @input="updateSelectedFilter(name, $event)"
                        v-if="['select', 'bool'].includes(filter.type)"
                    />

                    <activix-input
                        :value="selectedFilters[name]"
                        @input="updateSelectedFilter(name, $event)"
                        v-else-if="filter.type === 'text'"
                    />
                </activix-tooltip>
            </div>
        </div>

        <div class="flex justify-center gap-x-3 p-6 border-t border-gray-200 bg-gray-50">
            <activix-button @click="reset">
                {{ $t('filters.reset') }}
            </activix-button>
            <activix-button color="blue" @click="apply">
                {{ $t('filters.apply') }}
            </activix-button>
        </div>
    </div>
</template>

<script>
    import { ActivixButton, ActivixInput, ActivixSelect } from '@activix/ui';

    import ActivixLabel from '@/components/elements/ActivixLabel';

    export default {
        components: {
            ActivixButton,
            ActivixInput,
            ActivixLabel,
            ActivixSelect,
        },

        props: {
            filters: {
                type: Object,
                required: true,
            },
        },

        data: () => ({
            selectedFilters: {},
        }),

        computed: {
            availableFilters() {
                return Object.fromEntries(
                    Object.entries(this.filters)
                        .filter(([, filter]) => !filter.invalid),
                );
            },
        },

        watch: {
            availableFilters: {
                immediate: true,
                async handler() {
                    await this.$nextTick();

                    this.selectedFilters = Object.fromEntries(
                        Object.entries(this.availableFilters)
                            .map(([name, filter]) => [name, this.normalizeFilterValueIn(name, filter)]),
                    );
                },
            },
        },

        methods: {
            apply() {
                const normalizedSelectedFilters = Object.fromEntries(
                    Object.entries(this.selectedFilters)
                        .map(([name, selectedValue]) => {
                            const type = this.availableFilters[name]?.type;
                            const value = this.normalizeFilterValueOut({ type, value: selectedValue });

                            return [name, value];
                        }),
                );

                this.$emit('apply', normalizedSelectedFilters);
            },

            getSelectOptions(filter) {
                if (filter.type === 'bool') {
                    return [
                        {
                            value: 'yes',
                            text: this.$t('general.yes'),
                        },
                        {
                            value: 'no',
                            text: this.$t('general.no'),
                        },
                    ];
                }

                return filter.options || [];
            },

            getSelectedValue(name) {
                const selectedValue = this.selectedFilters[name];

                if (!selectedValue) {
                    return null;
                }

                if (Array.isArray(selectedValue) && !selectedValue.length) {
                    return null;
                }

                return selectedValue;
            },

            getValue(name, filter) {
                const value = this.selectedFilters[name];

                if (filter.type === 'select' && filter.multiple) {
                    return value || [];
                }

                return value;
            },

            normalizeBoolValueIn(value) {
                if (value === true) {
                    return 'yes';
                }

                if (value === false) {
                    return 'no';
                }

                return null;
            },

            normalizeBoolValueOut(value) {
                if (value === 'yes') {
                    return true;
                }

                if (value === 'no') {
                    return false;
                }

                return null;
            },

            normalizeFilterValueIn(name, filter) {
                const selectedValue = this.getSelectedValue(name);

                if (selectedValue) {
                    return selectedValue;
                }

                if (filter.type === 'bool') {
                    return this.normalizeBoolValueIn(filter.value);
                }

                return filter.value;
            },

            normalizeFilterValueOut(filter) {
                if (filter.type === 'bool') {
                    return this.normalizeBoolValueOut(filter.value);
                }

                return filter.value;
            },

            reset() {
                this.selectedFilters = {};

                this.$emit('reset');
            },

            updateSelectedFilter(filterName, filterValue) {
                this.selectedFilters[filterName] = filterValue;

                this.$emit('filter-selected', {
                    filterName,
                    filterValue,
                });
            },
        },
    };
</script>
