<template>
    <div>
        <div class="p-6 bg-white | group transition hover:bg-gray-100" :class="{ 'sm:pl-27': depth >= 1 }">
            <div class="flex items-start gap-3 relative">
                <!-- ANCHOR LINE -->
                <div class="absolute top-0 left-0 h-full mt-12 pt-px z-[5] | hidden sm:block" v-if="showReplyAnchor">
                    <div class="h-full w-full ml-6 border-l-2 border-gray-300" />
                </div>

                <!-- AVATAR -->
                <note-avatar class="hidden sm:inline-flex" :user="ownerUser" />

                <!-- INFO -->
                <div class="flex-1 flex flex-col justify-between items-stretch gap-3 | sm:flex-row">
                    <div class="space-y-1.5 w-full">
                        <div class="flex justify-between items-center gap-3">
                            <div class="flex items-center gap-3">
                                <span class="font-semibold" v-text="ownerUsername" />

                                <activix-tooltip :content="$t('clientCard.privateNote')" v-if="note.private">
                                    <icon name="regular/view-off" />
                                </activix-tooltip>

                                <icon name="regular/microphone" v-else-if="note.media_id && !shouldDisplayAudio" />

                                <note-replying-to-widget
                                    :depth="depth"
                                    :note="note"
                                    :parent-note="parentNote"
                                />

                                <span class="dot-flashing" v-if="isReplying" />

                                <span
                                    class="px-3 py-0.5 rounded-full text-sm text-white bg-blue-500 | animate-pulse"
                                    v-text="$t('clientCard.boxes.notes.editingThisNote')"
                                    v-else-if="isEditing"
                                />
                            </div>

                            <div class="text-right text-grey-600 | sm:hidden">
                                <activix-tooltip :content="noteDateTime">
                                    <span class="text-sm" v-text="noteDate" />
                                </activix-tooltip>
                                <div
                                    class="text-grey-600 text-xs"
                                    v-text="noteEditedOn"
                                    v-if="note.content || !note.file_url && !note.media_id"
                                />
                            </div>
                        </div>

                        <div class="group relative flex flex-col justify-center items-start gap-1.5">
                            <div class="whitespace-pre-line break-words" v-if="note.content || !note.file_url && !note.media_id">
                                <span v-html="formattedContent" />
                            </div>
                            <div class="py-2 w-full" :class="{ 'h-20': shouldDisplayAudio }" v-if="note.media_id">
                                <audio-player :file="audioFile" placeholder v-if="shouldDisplayAudio" />
                            </div>
                            <div class="mt-1" v-if="note.file_url">
                                <a
                                    class="flex items-center p-1.5 gap-1.5 rounded shadow bg-white"
                                    :href="note.file_url"
                                    target="_blank"
                                >
                                    <img
                                        :src="note.file_url"
                                        :alt="getFileName(note.file_url)"
                                        class="object-contain max-h-48 border border-gray-300 rounded-sm"
                                        v-if="attachmentIsImage"
                                    />

                                    <template v-else>
                                        <icon class="text-xl text-gray-700" name="regular/common-file-text" />

                                        <span v-text="attachmentFileName" />
                                    </template>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="flex-shrink-0 flex flex-row justify-between items-end gap-3 text-right text-grey-600 | sm:flex-col sm:justify-between">
                        <div class="flex flex-col gap-1.5 ml-3">
                            <div class="hidden sm:block">
                                <activix-tooltip :content="noteDateTime">
                                    <span class="text-sm" v-text="noteDate" />
                                </activix-tooltip>
                                <div
                                    class="text-grey-600 text-xs"
                                    v-text="noteEditedOn"
                                    v-if="note.content || !note.file_url && !note.media_id"
                                />
                            </div>

                            <activix-tooltip placement="bottom" :content="alertedUsersTooltip" v-if="alertedUsers.length">
                                <div class="self-end flex -space-x-5">
                                    <template v-for="(alertedUser, index) in alertedUsers">
                                        <figure class="p-1 rounded-full shadow bg-white" :key="index">
                                            <activix-avatar
                                                class="rounded-full text-gray-700 bg-gray-200"
                                                :size="27"
                                                :username="alertedUser.fullName"
                                                :src="alertedUser.hasProfilePicture() ? alertedUser.profile_picture : ''"
                                            />
                                        </figure>
                                    </template>
                                </div>
                            </activix-tooltip>
                        </div>

                        <!-- ACTION BUTTONS -->
                        <div
                            class="flex justify-center items-center gap-1.5 | transition group-hover:opacity-100 | sm:opacity-0"
                            v-if="!readonly"
                        >
                            <activix-tooltip :content="$t('clientCard.reply')">
                                <activix-button
                                    class="rounded"
                                    type="white"
                                    size="small"
                                    @click="$emit('trigger-replying', { note, rootNoteId })"
                                >
                                    <icon name="regular/email-action-reply" />
                                </activix-button>
                            </activix-tooltip>

                            <activix-tooltip :content="$t('clientCard.edit')" v-if="canEditNote">
                                <activix-button
                                    class="rounded"
                                    size="small"
                                    type="white"
                                    @click="$emit('trigger-editing', { note, rootNoteId })"
                                >
                                    <icon name="regular/pencil-1" />
                                </activix-button>
                            </activix-tooltip>

                            <activix-tooltip :content="$t('clientCard.delete')" v-if="canEditNote">
                                <activix-button
                                    class="rounded"
                                    :loading="$wait.is(`deleting.note[${note.id}]`)"
                                    size="small"
                                    type="white"
                                    @click="$emit('trigger-deleting', { note, rootNoteId })"
                                >
                                    <icon name="regular/trash-1" />
                                </activix-button>
                            </activix-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative" v-if="hasChildren">
            <!-- ANGLED ANCHOR LINE -->
            <div
                class="absolute top-0 left-0 h-6 w-15 mt-6 ml-12 border-l-2 border-b-2 border-gray-300 rounded-bl-lg z-[5] | hidden sm:block"
                v-if="depth === 0"
            />

            <note-list
                :account="account"
                :context-note-data="contextNoteData"
                :depth="depth + 1"
                :last-note-id-in-group="lastNoteIdInGroup"
                :note-type-filter="noteTypeFilter"
                :notes="note.children"
                :parent-note="note"
                :readonly="readonly"
                :root-note-id="rootNoteId"
                @trigger-deleting="$emit('trigger-deleting', $event)"
                @trigger-editing="$emit('trigger-editing', $event)"
                @trigger-replying="$emit('trigger-replying', $event)"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import autolinker from 'autolinker';

    import AudioPlayer from '@/components/audio/AudioPlayer';
    import NoteAvatar from '@/components/container/lead/notes/NoteAvatar.vue';
    import NoteReplyingToWidget from '@/components/container/lead/notes/NoteReplyingToWidget.vue';

    import Account from '@/entities/Account';
    import Comment from '@/entities/Comment.js';
    import CommentType from '@/entities/CommentType';
    import NoteAction from '@/entities/NoteAction';
    import User from '@/entities/User.js';

    export default {
        components: {
            AudioPlayer,
            NoteAvatar,
            NoteList: () => import('./NoteList.vue'),
            NoteReplyingToWidget,
        },

        props: {
            account: {
                type: Account,
                required: true,
            },
            contextNoteData: {
                type: Object,
                required: true,
            },
            depth: {
                type: Number,
                required: true,
            },
            lastNoteIdInGroup: {
                type: Number,
                default: null,
            },
            note: {
                type: Comment,
                required: true,
            },
            noteTypeFilter: {
                type: Array,
                default: () => [],
            },
            parentNote: {
                type: Comment,
                default: () => new Comment(),
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            rootNoteId: {
                type: Number,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            alertedUsers() {
                return this.note.alert_users
                    .map(item => {
                        const user = this.account.users.find(accountUser => accountUser.id === item);

                        return user ? new User(user) : null;
                    })
                    .filter(item => item);
            },

            alertedUsersTooltip() {
                if (this.alertedUsers.length <= 1) {
                    return '';
                }

                return this.alertedUsers.map(user => user.fullName).join(', ');
            },

            attachmentFileName() {
                if (!this.note.file_url) {
                    return '';
                }

                return this.note.file_url.split('/').pop();
            },

            attachmentIsImage() {
                if (!this.note.file_url) {
                    return false;
                }

                return ['.png', '.jpg', '.jpeg'].some(type => {
                    return this.note.file_url.toLowerCase().endsWith(type);
                });
            },

            audioFile() {
                return this.note.media;
            },

            canEditNote() {
                return this.authUser.isAdmin() || this.createdByAuthUser;
            },

            createdByAuthUser() {
                return this.authUser.isSameUser(this.ownerUser.id);
            },

            formattedContent() {
                let content = this.note.content;

                if (this.note.request_user_id) {
                    content = `${this.$t('clientCard.assignationRequest')} : ${content}`;
                }

                return autolinker.link(content, {
                    sanitizeHtml: true,
                });
            },

            hasChildren() {
                return this.note.children?.length > 0;
            },

            isContextNote() {
                return this.contextNoteData.note?.id === this.note.id;
            },

            isEditing() {
                return this.isContextNote && this.contextNoteData.action === NoteAction.EDIT;
            },

            isReplying() {
                return this.isContextNote && this.contextNoteData.action === NoteAction.REPLY;
            },

            noteDate() {
                const createdAt = as_locale(this.note.created_at, 'created_at');

                if (createdAt.isSameDay(now())) {
                    return createdAt.fromNow()[0].toUpperCase() + createdAt.fromNow().slice(1);
                }

                return createdAt.humanShort(true);
            },

            noteDateTime() {
                const createdAt = as_locale(this.note.created_at, 'created_at');

                if (createdAt.isSameDay(now())) {
                    return '';
                }

                return createdAt.timeHumanShort();
            },

            noteEditedOn() {
                const createdAt = as_locale(this.note.created_at, 'created_at');
                const updatedAt = as_locale(this.note.updated_at, 'updated_at');

                if (updatedAt.isSame(createdAt)) {
                    return '';
                }

                if (updatedAt.isSameDay(now())) {
                    return `${this.$t('clientCard.edited')} ${updatedAt.fromNow()}`;
                }

                return `${this.$t('clientCard.edited')} ${updatedAt.humanShort(true)}`;
            },

            ownerUser() {
                return new User(this.note.user);
            },

            ownerUsername() {
                return this.ownerUser.fullName || 'Activix';
            },

            shouldDisplayAudio() {
                return !this.noteTypeFilter.length ||
                    this.noteTypeFilter.includes(CommentType.AUDIO);
            },

            showReplyAnchor() {
                if (this.depth === 0) {
                    return this.hasChildren;
                }

                return this.note.id !== this.lastNoteIdInGroup;
            },
        },
    };
</script>
