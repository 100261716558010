<template>
    <thead class="sticky inset-x-0 top-0 z-20 bg-white">
        <tr>
            <slot />
        </tr>
    </thead>
</template>

<script>
    export default {};
</script>
